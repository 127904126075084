import React from "react";
import { useTranslation } from "react-i18next";

function DataAnalystDetails({ offer }) {
  const { t } = useTranslation();
  // Work Type Options
  const workType = [
    {
      name: t("offers.offer_create.data_analyst.collecting_data"),
      value: "collectingData",
    },
    {
      name: t("offers.offer_create.data_analyst.process_data"),
      value: "processData",
    },
    {
      name: t("offers.offer_create.data_analyst.analyze_data"),
      value: "analyzeData",
    },
    {
      name: t("offers.offer_create.data_analyst.report_insights"),
      value: "reportInsights",
    },
  ];

  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Deadlines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];
  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.website.what_work")}</p>
      <ul className="answers">
        {offer.workType.map((item) => (
          <li>{workType.find((work) => work.value === item).name}</li>
        ))}
      </ul>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.desired_location")}</p>
      <p className="detailsValue">{workMode.find((item) => item.value === offer.workMode).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
    </div>
  );
}

export default DataAnalystDetails;
