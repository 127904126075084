import React from "react";
import { useTranslation } from "react-i18next";

function LifeCoachDetails({ offer }) {
  const { t } = useTranslation();
  const gender = [
    { name: t("offers.offer_create.coach.gender_male"), value: "male" },
    { name: t("offers.offer_create.coach.gender_female"), value: "female" },
  ];
  const contactType = [
    {
      name: t("offers.offer_create.coach.contact_personal_contact"),
      value: "personal",
    },
    {
      name: t("offers.offer_create.coach.contact_remote_contact"),
      value: "remote",
    },
    {
      name: t(
        "offers.offer_create.coach.contact_partly_person_partly_remotely"
      ),
      value: "partlyPersonAndRemotely",
    },
  ];

  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];


  const subCoachType = [
    {
      name: t("offers.offer_create.coach.coachsub_no_preference"),
      value: "noPreference",
    },
    {
      name: t("offers.offer_create.coach.coachsub_both_feet_and_ground"),
      value: "bothFeetAndGround",
    },
    {
      name: t("offers.offer_create.coach.coachsub_soft_matters"),
      value: "softMatters",
    },
  ];

  const workSituationLifeCoach = [
    {
      name: t("offers.offer_create.coach.work_situation_salaried"),
      value: "salaried",
    },
    {
      name: t("offers.offer_create.coach.work_situation_entrepreneur"),
      value: "entrepreneur",
    },
    {
      name: t("offers.offer_create.coach.work_situation_looking_for_work"),
      value: "lookingForWork",
    },
    {
      name: t(
        "offers.offer_create.coach.work_situation_just_graduated_starter"
      ),
      value: "justGraduatedStarter",
    },
    {
      name: t("offers.offer_create.coach.work_situation_student"),
      value: "student",
    },
    {
      name: t("offers.offer_create.coach.work_situation_retired"),
      value: "retired",
    },
    {
      name: t("offers.offer_create.coach.work_situation_otherwise"),
      value: "otherwise",
    },
  ];
  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_work_situation")}</p>
      <p className="detailsValue">{
          workSituationLifeCoach.find(
            (item) => item.value === offer.workSituation
          ).name
        }
      </p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_gender")}</p>
      <p className="detailsValue">{gender.find((item) => item.value === offer.gender).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_age")}</p>
      <p className="detailsValue">{offer.age}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_sub_coach_type")}</p>
      <p className="detailsValue">{subCoachType.find((item) => item.value === offer.subCoachType).name}
      </p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_contact_type")}</p>
      <p className="detailsValue">{contactType.find((item) => item.value === offer.contactType).name}</p>
    </div>
  );
}

export default LifeCoachDetails;
