import React, { useEffect, useRef, useState } from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import API from "../../../config/axios";
import CustomDialogBox from "../../../components/CustomDialogBox";
import { toast } from "react-hot-toast";
import { highlightField } from "../../../utils/Commons";

const PurchaseOfferDialogBox = ({
  isOpen,
  onClose,
  offer,
  getProfessionalOfferList,
}) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  // eslint-disable-next-line
  const offerController = useRef(new AbortController());
  const [userId, setUserId] = useState();

  useEffect(() => {
    let user = localStorage.getItem("dashbook_user")
      ? JSON.parse(localStorage.getItem("dashbook_user"))
      : {};
    setUserId(user.id);
    // eslint-disable-next-line
  }, []);

  const applyOffer = () => {
    const offerId = offer._id;
    const credit = offer.credit;

    if (userId && offerId && credit) {
      // Navigate To Path
      offerController.current.abort();
      offerController.current = new AbortController();

      API.put(
        `/offer/apply/${offerId}`,
        { userId },
        {
          signal: offerController.current.signal,
        },
      )
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            onClose();
            getProfessionalOfferList();
            toast.success("Applied to the Offer");
          } else {
            if (resData && resData.type === "VALIDATION") {
              // setInputErrors(resData.error);
              try {
                highlightField(Object.keys(resData.error)[0]);
              } catch (error) {
                console.warn("Field cannot be highlighted");
              }
            } else {
              toast.error(resData?.error?.message || "Something went wrong");
            }
          }
        })
        .catch((error) => {
          if (!error.code || error.code !== "ERR_CANCELED") {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <CustomDialogBox
      title={t("offer.professional.professionalList.applyOffer")}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Stack
          mr={1}
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              onClose();
            }}
          >
            {t("otherBooking.no")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              applyOffer();
            }}
          >
            {t("offer.professional.companyProfile.offer")}
          </Button>
        </Stack>
      }
    >
      <div>
        {t("offer.professional.professionalList.creditsUsed")} {offer.credit}
        &nbsp;credits
      </div>
    </CustomDialogBox>
  );
};

export default PurchaseOfferDialogBox;
