import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useEffect } from "react";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";

export default function FieldsExpertise() {
  const { t } = useTranslation();

  const [fieldsData, setFieldsData] = React.useState({
    accountant: [
      {
        name: "completeAdministration",
        label: t(
          "offer.professional.fieldsExpertise.labels.completeAdministration",
        ),
        checked: false,
      },
      {
        name: "annualAccTaxReturns",
        label: t("offer.professional.fieldsExpertise.labels.annualAccounts"),
        checked: false,
      },
      {
        name: "taxReturns",
        label: t("offer.professional.fieldsExpertise.labels.taxReturns"),
        checked: false,
      },
      {
        name: "taxAdvice",
        label: t("offer.professional.fieldsExpertise.labels.taxAdvice"),
        checked: false,
      },
      {
        name: "auditorsReport",
        label: t("offer.professional.fieldsExpertise.labels.auditorsReport"),
        checked: false,
      },
      {
        name: "drawObjectionLetter",
        label: t(
          "offer.professional.fieldsExpertise.labels.drawObjecttionLetter",
        ),
        checked: false,
      },
      {
        name: "drawBusinessPlan",
        label: t(
          "offer.professional.fieldsExpertise.labels.drawUpBuisnessPlan",
        ),
        checked: false,
      },
      {
        name: "otherActivities",
        label: t("offer.professional.fieldsExpertise.labels.otherActivities"),
        checked: false,
      },
    ],
    developer: [
      {
        name: "website",
        label: t(
          "offer.professional.fieldsExpertise.labels.buildingWebsiteShops",
        ),
        checked: false,
      },
      {
        name: "apps",
        label: t("offer.professional.fieldsExpertise.labels.buildingApps"),
        checked: false,
      },
      {
        name: "seoAndSea",
        label: t("offer.professional.fieldsExpertise.labels.seoInSea"),
        checked: false,
      },
    ],
    graphicDesigner: [
      {
        name: "logoDesign",
        label: t("offer.professional.fieldsExpertise.labels.logoDesign"),
        checked: false,
      },
      {
        name: "corporateIdentityDesign",
        label: t("offer.professional.fieldsExpertise.labels.corporateIdentity"),
        checked: false,
      },
      {
        name: "websiteDesign",
        label: t("offer.professional.fieldsExpertise.labels.websiteDesign"),
        checked: false,
      },
      {
        name: "animationsOrIllustration",
        label: t(
          "offer.professional.fieldsExpertise.labels.createAnimationsOrIllustrations",
        ),
        checked: false,
      },
      {
        name: "printedMatter",
        label: t(
          "offer.professional.fieldsExpertise.labels.preparingPrintedManner",
        ),
        checked: false,
      },
      {
        name: "other",
        label: t("offer.professional.fieldsExpertise.labels.other"),
        checked: false,
      },
    ],
    dataAnalyst: [
      {
        name: "collectingData",
        label: t("offer.professional.fieldsExpertise.labels.collectingData"),
        checked: false,
      },
      {
        name: "processData",
        label: t("offer.professional.fieldsExpertise.labels.processData"),
        checked: false,
      },
      {
        name: "analyzeData",
        label: t("offer.professional.fieldsExpertise.labels.analyzeData"),
        checked: false,
      },
      {
        name: "reportInsights",
        label: t("offer.professional.fieldsExpertise.labels.reportInsights"),
        checked: false,
      },
    ],
    coach: [
      {
        name: "careerCoach",
        label: t("offer.professional.fieldsExpertise.labels.careerCoach"),
        checked: false,
      },
      {
        name: "businessCoach",
        label: t("offer.professional.fieldsExpertise.labels.businessCoach"),
        checked: false,
      },
      {
        name: "lifeCoach",
        label: t("offer.professional.fieldsExpertise.labels.lifeCoach"),
        checked: false,
      },
      {
        name: "emotionalCoach",
        label: t(
          "offer.professional.fieldsExpertise.labels.helpWithEmotionalRecovery",
        ),
        checked: false,
      },
      {
        name: "budgetCoach",
        label: t("offer.professional.fieldsExpertise.labels.budgetCoach"),
        checked: false,
      },
    ],
    advocate: [
      {
        name: "checkingContract",
        label: t("offer.professional.fieldsExpertise.labels.checkingContract"),
        checked: false,
      },
      {
        name: "checkingTermsConditions",
        label: t(
          "offer.professional.fieldsExpertise.labels.checkingTermsConditions",
        ),
        checked: false,
      },
      {
        name: "taxMatters",
        label: t("offer.professional.fieldsExpertise.labels.tax_matters"),
        checked: false,
      },
      {
        name: "businessConflict",
        label: "zakelijk conflict",
        checked: false,
      },
      {
        name: "familyMatters",
        label: "Familiezaken (erfenis, scheiding, ed)",
        checked: false,
      },
      {
        name: "realEstate",
        label: "Huur/ vastgoed",
        checked: false,
      },
      {
        name: "personalInjury",
        label: "Letselschade",
        checked: false,
      },
      {
        name: "other",
        label: "Anders (geef toelichting)",
        checked: false,
      },
    ],
  });

  // Handle changes in checkbox states for each expertise field
  const handleChangeChild = (fieldName, index) => (event) => {
    const updatedFieldsData = { ...fieldsData };
    updatedFieldsData[fieldName][index].checked = event.target.checked;
    setFieldsData(updatedFieldsData);
  };

  // Handle changes in the parent checkbox for each expertise field
  const handleChangeParent = (fieldName) => {
    const allChecked = fieldsData[fieldName].every((value) => value.checked);
    const updatedFieldsData = fieldsData[fieldName].map((item) => ({
      ...item,
      checked: !allChecked,
    }));
    setFieldsData({ ...fieldsData, [fieldName]: updatedFieldsData });
  };

  // Get user expertise data from API
  const getUserExpertiseData = async () => {
    try {
      const res = await API.get("/offer/notification/preference");
      const resData = res.data;

      if (resData.status) {
        if (resData.data) {
          const updatedFieldsData = { ...fieldsData };
          Object.keys(updatedFieldsData).forEach((fieldName) => {
            updatedFieldsData[fieldName] = updatedFieldsData[fieldName].map(
              (item) => {
                return {
                  ...item,
                  checked: resData.data[fieldName]?.includes(item?.name),
                };
              },
            );
          });

          setFieldsData(updatedFieldsData);
        }
      } else {
        toast.error("notification Info cannot be fetched");
      }
    } catch (error) {
      console.log("err", error);
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getUserExpertiseData();
    // eslint-disable-next-line
  }, []);

  const debounceApiCall = debounce((data) => {
    API.post("/offer/notification/", data)
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          console.log(resData, "resData");
        } else {
          toast.error("notification Info cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  }, 1000);

  useEffect(() => {
    if (fieldsData) {
      const formData = {
        isAccountant: true,
        isDeveloper: false,
        isGraphicDesigner: false,
        isDataAnalyst: false,
        isCoach: false,
        isAdvocate: false,
        accountant: fieldsData.accountant
          .filter((item) => item.checked)
          .map((item) => item.name),
        developer: fieldsData.developer
          ?.filter((item) => item.checked)
          .map((item) => item.name),
        graphicDesigner: fieldsData.graphicDesigner
          ?.filter((item) => item.checked)
          .map((item) => item.name),
        dataAnalyst: fieldsData.dataAnalyst
          ?.filter((item) => item.checked)
          .map((item) => item.name),
        coach: fieldsData.coach
          ?.filter((item) => item.checked)
          .map((item) => item.name),
        advocate: fieldsData.advocate
          ?.filter((item) => item.checked)
          .map((item) => item.name),
      };
      debounceApiCall(formData);
    }
    // eslint-disable-next-line
  }, [fieldsData]);

  // Render checkboxes and labels for each expertise field
  const renderExpertiseField = (fieldName) => {
    console.log("fieldName", fieldName);
    return (
      <div style={{ marginTop: "10px" }}>
        <FormControlLabel
          label={t(`offer.professional.fieldsExpertise.${fieldName}`)}
          control={
            <Checkbox
              checked={fieldsData[fieldName]?.every((value) => value.checked)}
              indeterminate={
                !fieldsData[fieldName]?.every((value) => value.checked) &&
                fieldsData[fieldName]?.some((value) => value.checked)
              }
              onChange={() => handleChangeParent(fieldName)}
            />
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {fieldsData[fieldName]?.map((data, index) => {
            console.log("data", data);
            return (
              <FormControlLabel
                key={index}
                label={t(
                  `offer.professional.fieldsExpertise.labels.${data.name}`,
                )}
                control={
                  <Checkbox
                    checked={data.checked}
                    onChange={handleChangeChild(fieldName, index)}
                  />
                }
              />
            );
          })}
        </Box>
      </div>
    );
  };

  return (
    <>
      {renderExpertiseField("accountant")}
      <br />
      {renderExpertiseField("developer")}
      <br />
      {renderExpertiseField("graphicDesigner")}
      <br />
      {renderExpertiseField("dataAnalyst")}
      <br />
      {renderExpertiseField("coach")}
      <br />
      {renderExpertiseField("advocate")}
    </>
  );
}
