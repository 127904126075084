import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Card, CardContent, Typography, Stack, Grid } from "@mui/material";
import API from "../config/axios";
import { toast } from "react-hot-toast";
import CustomBtn from "../components/CustomBtn";
import { useTheme } from "@mui/material/styles";
import useQuery from "../hooks/query";
import TextInput from "../components/form/TextInput";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

export default function ForgotPassword() {
  const isDarkTheme = useTheme().palette.mode === "dark";
  const logoLink = isDarkTheme ? "/logo_light.svg" : "/logo.svg";
  const { t } = useTranslation();
  const navigate = useNavigate();

  const query = useQuery();
  const token = query.get("token");

  // redirect to login screen if token is not present in the URL
  useEffect(() => {
    let toastId;

    if (!token) {
      toastId = toast.error("Link is invalid, Please try again");
      navigate("/login", { replace: true });
    }

    return () => {
      if (toastId) {
        toast.remove(toastId);
      }
    };
    // eslint-disable-next-line
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("password is required")
      .min(8, "password must be at least 8 characters long"),
    confirmPassword: yup
      .string()
      .required("confirm password is required")
      .oneOf(
        [yup.ref("password")],
        "confirm password must be same as password"
      ),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const requestStartTime = Date.now();

    API.post(`auth/professional/reset-password?token=${token}`, {
      password: data.password,
      confirmPassword: data.confirmPassword,
    })
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          toast.success(resData.message);

          navigate("/login", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
          } else {
            toast.error("Link is invalid, Please try again");
            navigate("/login", { replace: true });
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  return (
    <>
      <img
        src={logoLink}
        width={"150px"}
        alt="logo"
        style={{ position: "relative", top: "30px", left: "30px" }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack sx={{ marginX: 2, marginY: 10 }}>
          <Card
            elevation={1}
            sx={{
              p: 2,
              maxWidth: "450px",
              width: "min(90vw, 450px)",
              boxShadow: isDarkTheme
                ? "rgba(0, 0, 0, 0.1) 0px 10px 50px"
                : "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={"medium"} gutterBottom>
                      Reset Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="password"
                      type="password"
                      label="Password"
                      control={control}
                      customError={inputErrors.password || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                      control={control}
                      customError={inputErrors.confirmPassword || null}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <CustomBtn
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="large"
                      disableRipple
                      type="submit"
                      loading={isLoading}
                    >
                      {t("common_btn.submit")}
                    </CustomBtn>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </>
  );
}
