import { Controller } from "react-hook-form";
import { Checkbox, Stack, Typography } from "@mui/material";

const CheckBox = ({
  name,
  errorStyle,
  control,
  label,
  helperText = null,
  rules = { required: false },
  customError = null,
  ...otherProps
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value = false },
          fieldState: { error },
        }) => {
          return (
            <>
              <Checkbox
                // id={name}
                variant="outlined"
                color="info"
                value={value}
                checked={value}
                onChange={onChange}
                error={!!error || !!customError}
                helperText={
                  error ? error.message : customError || helperText || null
                }
                {...otherProps}
              />
              <Typography component="span" variant="body2">
                {label}
              </Typography>
              <Stack>

                <Typography component="span" variant="caption" color="error" style={errorStyle}>
                  {error ? error.message : customError || helperText || null}
                </Typography>
              </Stack>

            </>
          );
        }}
      />
    </>
  );
};

export default CheckBox;
