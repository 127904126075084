import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function About() {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          padding: "2rem",
          paddingTop: "3rem",
          backgroundColor: "black",
        }}
      >
        <Typography variant="subtitle1" align="center">
          {t("offers.about_page.we_are_quotation")}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: "bold", color: "white" }}
        >
          {t("offers.about_page.group_of_key_players")}
        </Typography>
      </div>

      <div
        style={{
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          className="themeColor"
          align="center"
          sx={{ margin: "2rem 0px" }}
        >
          {t("offers.about_page.find_perfect_business")}
        </Typography>
        <Typography variant="h6">
          {t("offers.about_page.looking_for_partner")}
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: "2rem" }}>
          {t("offers.about_page.supply_and_demand_services")}
        </Typography>
      </div>

      <div
        style={{
          padding: "2rem",
          paddingTop: "2rem",
          backgroundColor: "black",
        }}
      >
        <Typography variant="h6" className="themeColor" align="center">
          {t("offers.about_page.connecting__dashbook_quote")}
        </Typography>
        <Grid container sx={{ paddingTop: "1rem" }}>
          <Grid item xs={12} md={5} sm={12} sx={{ textAlign: "center" }}>
            <img alt="" src={"./AboutImage.png"} style={{ width: "70%" }} />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sm={12}
            sx={{ alignSelf: "center", margin: "2rem 0px" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
                border: "1px solid white",
                padding: "1rem",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Link
                className="themeColor"
                style={{ textDecoration: "none" }}
                to={"https://offerte.dashbook.nl/"}
              >
                {t("offers.about_page.commitment_customers_link")}
              </Link>
              {t("offers.about_page.commitment_customers")}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          padding: "1rem",
          border: "3px solid black",
          margin: "1rem",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          {t("offers.about_page.frustration_as_a_starting_point")}
        </Typography>
        <Typography variant="subtitle1">
          {t("offers.about_page.our_concept")}
        </Typography>

        <Typography variant="subtitle1" sx={{ margin: "1rem 0px" }}>
          {t("offers.about_page.the_solution_dashbook_quote")}
        </Typography>
        <Typography variant="subtitle1">
          {t("offers.about_page.successfully_used_our_platform")}
        </Typography>
      </div>
      <div
        style={{
          padding: "1rem",
          border: "3px solid black",
          margin: "1rem",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          {t("offers.about_page.how_does_dashbook_work_dashbook_quote")}
        </Typography>
        <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
          {t("offers.about_page.the_process_is_simple_and_efficient")}
        </Typography>
        <Typography variant="subtitle1">
          {t("offers.about_page.within_24_hours_applicant_receives_quotes")}
        </Typography>
      </div>

      <div
        style={{
          color: "white",
          backgroundColor: "black",
          padding: "2rem",
        }}
      >
        <Grid container justifyContent={"left"} sx={{ position: "relative" }}>
          <Grid item xs={12} md={8} sm={12} className="ballonBox">
            <div className="underLineText">
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.our_core_values").substring(0, 4)}
              </Typography>

              <Typography
                variant="h4"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.our_core_values").substring(4)}
              </Typography>
            </div>

            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.involvement")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.customers_need")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.reliability")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.transparent_and_reliable_partner")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.agility")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.we_adapt_flexibly")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.comfort")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.user_friendly_experience")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"right"}
          sx={{ position: "relative", minHeight: "400px" }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sm={12}
            className="ballonBox"
            sx={{
              position: "absolute",
              top: "-100px",
              left: "30%",
              width: "inherit",
            }}
          >
            <div className="underLineText">
              <Typography
                variant="h4"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.why_dashbook_quote").substring(0, 6)}
              </Typography>
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.why_dashbook_quote").substring(6)}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.easily_efficient")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.place_your_order_for_free")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.large_network")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.professionals_in_various_industries")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.transparent")}
              </Typography>
              <Typography variant="subtitle1">
                {t(
                  "offers.about_page.compare_quotes_based_price_quality_experience"
                )}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.safe_reliable")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.secure_payment")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"left"}
          sx={{ position: "relative", minHeight: "400px" }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sm={12}
            className="ballonBox"
            sx={{
              position: "absolute",
              top: "-180px",
              left: "0%",
              width: "inherit",
            }}
          >
            <div className="underLineText">
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.discover_the_benefits").substring(0, 9)}
              </Typography>
              <Typography
                variant="h4"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.discover_the_benefits").substring(9, 19)}
              </Typography>
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.discover_the_benefits").substring(19)}
              </Typography>
            </div>

            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.save_time_and_money")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.solution_for_your_needs")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.compare_quotes")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.choose_from_wide_range_of_offers")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.find_the_perfect_match")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.work_with_trusted_professionals")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1">
                {t("offers.about_page.develop_your_business")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.grow_your_customer_base")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"right"}
          sx={{ position: "relative", minHeight: "150px" }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sm={12}
            className="ballonBox"
            sx={{
              position: "absolute",
              top: "-250px",
              left: "30%",
              width: "inherit",
            }}
          >
            <div className="underLineText">
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.advanced_dashboards_and_tools").substring(
                  0,
                  12
                )}
              </Typography>
              <Typography
                variant="h4"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.advanced_dashboards_and_tools").substring(
                  13,
                  23
                )}
              </Typography>
              <Typography
                variant="h6"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.advanced_dashboards_and_tools").substring(
                  24,
                  26
                )}
              </Typography>
              <Typography
                variant="h4"
                className="themeColor"
                sx={{
                  fontStyle: "italic",
                  display: "inline-block",
                  paddingRight: "4px",
                }}
              >
                {t("offers.about_page.advanced_dashboards_and_tools").substring(
                  26
                )}
              </Typography>
            </div>

            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1" style={{ minWidth: "200px" }}>
                {t("offers.about_page.clear_dashboards")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.get_real_time_insight")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1" style={{ minWidth: "110px" }}>
                {t("offers.about_page.useful_tools")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.filters_sorting_features")}
              </Typography>
            </div>
            <div className="flex" style={{ gap: "5px" }}>
              <Typography variant="subtitle1" style={{ minWidth: "160px" }}>
                {t("offers.about_page.detailed_analytics")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.about_page.follow_trends")}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <Typography
        variant="h4"
        align="center"
        style={{
          textDecoration: "underline",
          fontStyle: "italic",
          padding: "3rem 0px",
        }}
      >
        {t("offers.about_page.dashbook_quote_more_than_a_platform")}
      </Typography>
    </div>
  );
}

export default About;
