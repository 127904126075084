import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="pageStart" style={{ backgroundColor: "#F3F4F6" }}>
      <h1 style={{ textAlign: "center", marginBottom: "3rem" }}>
        {t("offers.privacy_policy_page.privacy_statement_dashbook_quote")}
      </h1>
      <div className="cardPage">
        <h3>{t("offers.privacy_policy_page.introduction")}</h3>
        <p>
          {t(
            "offers.privacy_policy_page.privacy_statement_provides_information"
          )}
        </p>
        <h3>{t("offers.privacy_policy_page.self_management_of_your_data")}</h3>

        <p>{t("offers.privacy_policy_page.your_personal_management_page")}</p>
        <h3>{t("offers.privacy_policy_page.data_controller")}</h3>
        <p>{t("offers.privacy_policy_page.when_you_post_a_request")}</p>
        <h3>{t("offers.privacy_policy_page.purposes_of_data_processing")}</h3>
        <p>
          {t(
            "offers.privacy_policy_page.distinguishes_between_two_types_of_users"
          )}
        </p>
        <h3>{t("offers.privacy_policy_page.clients")}</h3>
        <p>
          {t("offers.privacy_policy_page.personal_data_provided")}

          <ul>
            <li>{t("offers.privacy_policy_page.allowing_you_to_post")}</li>
            <li>{t("offers.privacy_policy_page.contact_by_email_or_phone")}</li>
            <li>{t("offers.privacy_policy_page.mailing_notifications")}</li>
            <li>
              {t("offers.privacy_policy_page.interested_professionals_contact")}
            </li>
            <li>{t("offers.privacy_policy_page.emailing_user_information")}</li>
            <li>
              {t(
                "offers.privacy_policy_page.comply_with_laws_and_regulations_applicable_to_dashbook"
              )}
            </li>
          </ul>
          {t("offers.privacy_policy_page.contact_details_will_private")}
        </p>
        <h3>{t("offers.privacy_policy_page.professionals")}</h3>
        <p>
          {t("offers.privacy_policy_page.conclusion_and_execution")}

          <ul>
            <li>{t("offers.privacy_policy_page.allowing_you_to_post")}</li>
            <li>
              {t("offers.privacy_policy_page.questions_regarding_your_account")}
            </li>
            <li>
              {t("offers.privacy_policy_page.daily_mailing_new_requests")}
            </li>
            <li>
              {t(
                "offers.privacy_policy_page.mailing_notifications_regarding_your_quotations"
              )}
            </li>
            <li>
              {t(
                "offers.privacy_policy_page.mailing_invoice_purchased_credits"
              )}
            </li>
            <li>
              {t(
                "offers.privacy_policy_page.listing_your_details_business_directory"
              )}
            </li>
            <li>{t("offers.privacy_policy_page.emailing_user_information")}</li>
            <li>
              {t(
                "offers.privacy_policy_page.comply_with_laws_and_regulations_applicable_to_dashbook"
              )}
            </li>
          </ul>
        </p>
        <h3>{t("offers.privacy_policy_page.cookies")}</h3>
        <p>
          {t("offers.privacy_policy_page.more_information_about_cookies")}
          <Link className="linkColor" to={"/cookie"}>
            {t("offers.privacy_policy_page.cookie_statement")}
          </Link>
        </p>
        <h3>{t("offers.privacy_policy_page.data_to_third_parties")}</h3>
        <p>{t("offers.privacy_policy_page.does_not_sell_your_data")}</p>
        <p>{t("offers.privacy_policy_page.applicable_laws_and_regulations")}</p>
        <p>{t("offers.privacy_policy_page.quote_may_pass_on_your_data")}</p>
        <h3>
          {t(
            "offers.privacy_policy_page.third_party_service_providers_and_other_sites"
          )}
        </h3>
        <p>{t("offers.privacy_policy_page.arrive_at_the_site")}</p>

        <h3>{t("offers.privacy_policy_page.retention_period")}</h3>
        <p>
          {t("offers.privacy_policy_page.does_not_store_your_personal_data")}
        </p>
        <h3>{t("offers.privacy_policy_page.privacy_and_security")}</h3>
        <p>{t("offers.privacy_policy_page.values_the_privacy")}</p>
        <p>{t("offers.privacy_policy_page.accepts_no_liability")}</p>

        <h3>{t("offers.privacy_policy_page.reporting_of_irregularities")}</h3>
        <p>
          {t("offers.privacy_policy_page.suspect_your_data_is_not_secured")}
          <Link className="linkColor" to={"/"}>
            {t("offers.privacy_policy_page.privacy_policy_dashbook_quote_nl")}
          </Link>
          .
        </p>
        <h3>{t("offers.privacy_policy_page.access_correction_and_rights")}</h3>
        <p>
          {t(
            "offers.privacy_policy_page.access_correct_delete_withdraw_your_consent"
          )}
          <Link className="linkColor" to={"/"}>
            {t("offers.privacy_policy_page.privacy_policy_dashbook_quote_nl")}
          </Link>
          {t("offers.privacy_policy_page.respond_within_four_weeks")}
        </p>
        <h3>{t("offers.privacy_policy_page.complaints_and_supervisor")}</h3>
        <p>
          {t("offers.privacy_policy_page.complaint_you_can_submit")}
          <Link
            className="linkColor underLine"
            to={
              "https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
            }
            target="_blank"
          >
            {t("offers.privacy_policy_page.administrative_data")}
          </Link>
          .
        </p>
        <h3>{t("offers.privacy_policy_page.changes_privacy_statement")}</h3>
        <p>{t("offers.privacy_policy_page.right_to_make_changes")}</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
