import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import { useState } from "react";
import dayjs from "dayjs";
import useDialogHooks from "../../components/Hooks/useDialogHooks";
import BuyCreditsDialogBox from "./modals/buyCredits";

export default function Credits() {
  const { t } = useTranslation();
  const [creditData, setCreditData] = useState({
    totalCredits: 0,
    lastPayment: {},
    recentPayments: [],
  });
  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();
  const [rows, setRows] = useState([]);

  const headCells = [
    {
      id: "no",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.fields.no"),
      sortable: true,
      width: "10px",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.fields.date"),
      sortable: true,
      width: "100px",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.fields.amount"),
      sortable: false,
      width: "100px",
    },
    {
      id: "credits",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.fields.credits"),
      sortable: false,
      width: "100px",
    },
    {
      id: "place",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.fields.description"),
      sortable: false,
      width: "200px",
    },
  ];

  const getCreditData = async () => {
    try {
      const res = await API.get("offer/credit_info");
      const resData = res.data;

      if (resData.status) {
        if (resData.data && resData.data.length !== 0) {
          setCreditData(resData.data);
          setRows(resData.data.recentPayments);
        }
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getCreditData();
    // eslint-disable-next-line
  }, []);

  const showNoData = rows && rows.length !== 0 ? false : true;

  return (
    <>
      <Stack>
        <Typography
          variant="h6"
          mt={1}
          // sx={{ fontSize: "20px", margin: "2px", flexGrow: 1 }}
        >
          {t("offer.professional.credits.yourCredits")}:&nbsp;&nbsp;
          {creditData?.totalCredits}
        </Typography>
        <Typography
          component="h6"
          sx={{ fontSize: "18px", margin: "2px", flexGrow: 1 }}
        >
          {t("offer.professional.credits.belowYouWillSee")}
        </Typography>
        <Typography
          component="h6"
          sx={{ fontSize: "18px", margin: "2px", flexGrow: 1 }}
        >
          {t("offer.professional.credits.youCurrentlyNoCredits")}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        disableElevation
        style={{
          backgroundColor: "#00c078", // Set the background color to green
          color: "#ffffff", // Set the text color to ensure readability
          marginTop: "15px",
          marginBottom: "15px",
        }}
        onClick={() => openDialog("showBuyCredits")}
      >
        {t("offer.professional.credits.buyCredits")}
      </Button>
      <Typography
        component="h6"
        sx={{
          fontSize: "18px",
          marginTop: "15px",
          marginBottom: "15px",
          flexGrow: 1,
        }}
      >
        {t("offer.professional.credits.purchaseHistory")}
      </Typography>
      <Paper
        sx={{
          backgroundColor: "primaryBg.light",
          // width: "max-content",
          margin: "1rem auto",
          width: "100%",
        }}
        // className={styles.custom_container}
        // elevation={2}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ borderRadius: "inherit" }}
        >
          <Table
            aria-label="custom pagination table"
            className={"custom-table br-0"}
            sx={{ backgroundColor: "primaryBg.light" }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    // align={headCell.numeric ? "right" : "left"}
                    // sx={{ textAlign: "center" }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {dayjs(row.createdAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell scope="row">
                    {row.creditInfo.amount?.toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </TableCell>
                  <TableCell scope="row">
                    {row.creditInfo.credits?.toLocaleString("nl-NL", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                </TableRow>
              ))}
              {showNoData}
              {showNoData && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: "grey",
                      }}
                      variant="body2"
                    >
                      {t("noDataAvailable")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {isDialogOpen("showBuyCredits") && (
        <BuyCreditsDialogBox
          isOpen={() => isDialogOpen("showBuyCredits")}
          onClose={() => closeDialog("showBuyCredits")}
        />
      )}
    </>
  );
}
