import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialogBox from "../../../components/CustomDialogBox";
import { useNavigate } from "react-router-dom";

const ShowContactDetailsDialogBox = ({ isOpen, onClose, offer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <CustomDialogBox
        title={t("offer.professional.professionalList.applyOffer")}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <Stack
            mr={1}
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                onClose();
              }}
            >
              {t("offer.professional.professionalList.cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                navigate("/offers/add");
              }}
            >
              {t("offer.professional.professionalList.goToOffer")}
            </Button>
          </Stack>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.company_name")}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">{offer?.company?.name}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.address")}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">
              {offer?.company?.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.postalCode")}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">
              {offer?.company?.postalCode}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.email")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">{offer?.company?.email}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.phoneNo")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">
              {offer?.company?.phoneNo}
            </Typography>
          </Grid>
        </Grid>
      </CustomDialogBox>
    </>
  );
};

export default ShowContactDetailsDialogBox;
