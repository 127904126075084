import React, { useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

function CommanPage({ child }) {
  const [dialogOpen, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [component, setComponent] = useState();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  return (
    <>
      <Header
        isNavbarOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
        setTitle={setTitle}
        setComponent={setComponent}
        setOpen={setOpen}
        dialogOpen={dialogOpen}
        title={title}
        component={component}
      />
      {React.cloneElement(child, {
        setIsNavbarOpen,
        setTitle,
        setComponent,
        setOpen,
      })}
      <Footer
        setIsNavbarOpen={setIsNavbarOpen}
        setTitle={setTitle}
        setComponent={setComponent}
        setOpen={setOpen}
      />
    </>
  );
}

export default CommanPage;
