import { Box, Container, Tab, Tabs } from "@mui/material";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Loading from "../../components/LoadingPage";
import { useEffect } from "react";
import ProfessionalList from "./professionalList";
import QuotationsSubmittedList from "./quotationsSubmittedList";
import FieldsExpertise from "./fieldsExpertise";
import Credits from "./credits";

const AppsAndDeals = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  function handlePath(path) {
    switch (true) {
      case path === "/featured":
        return 0;
      case path === "/professionalList/quotesSubmitted":
        return 1;
      case path === "/professionalList/deals":
        return 2;
      case path.includes("/professionalList/my_apps"):
        return 3;
      case path.includes("/professionalList/my_deals"):
        return 4;
      default:
        return 0; // default case
    }
  }
  useEffect(() => {
    setActiveTab(handlePath(location.pathname));
    // eslint-disable-next-line
  }, []);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Container maxWidth="xl">
      <Box  >
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          onChange={handleTabChange}
        >
          <Tab
            label={t("offer.professional.tabs.toRequest")}
            component={NavLink}
            to="featured"
            activeclassname="active"
          />
          <Tab
            label={t("offer.professional.tabs.quotationsSubnitted")}
            component={NavLink}
            to="quotesSubmitted"
            activeclassname="active"
          />
          <Tab
            label={t("offer.professional.tabs.fieldsOfExpertise")}
            component={NavLink}
            to="expertiseFields"
            activeclassname="active"
          />
          <Tab
            label={t("offer.professional.tabs.yourCredits")}
            component={NavLink}
            to="credits"
            activeclassname="active"
          />
          {/* <Tab
            label={t("offer.professional.tabs.companyProfile")}
            component={NavLink}
            to="companyProfile"
            activeclassname="active"
          /> */}
        </Tabs>
      </Box>
      <Routes>
        <Route
          path="featured"
          element={
            <Suspense fallback={<Loading />}>
              <ProfessionalList />
            </Suspense>
          }
        />
        <Route
          path="quotesSubmitted"
          element={
            <Suspense fallback={<Loading />}>
              <QuotationsSubmittedList />
            </Suspense>
          }
        />
        <Route
          path="expertiseFields"
          element={
            <Suspense fallback={<Loading />}>
              <FieldsExpertise />
            </Suspense>
          }
        />
        <Route path="credits" element={<Credits />} />
        {/* <Route
          path="companyProfile"
          element={
            <Suspense fallback={<Loading />}>
              <CompanyProfile />
            </Suspense>
          }
        /> */}
      </Routes>
    </Container>
  );
};

export default AppsAndDeals;
