import React from "react";
import CareerCoachDetails from "./subCoachDetails/CareerCoachDetails";
import BusinessCoachDetails from "./subCoachDetails/BusinessCoachDetails";
import LifeCoachDetails from "./subCoachDetails/LifeCoachDetails";
import EmotionalCoachDetails from "./subCoachDetails/EmotionalCoachDetails";
import BudgetCoachDetails from "./subCoachDetails/BudgetCoachDetails";

function CoachDetails({ offer }) {
  return (
    <div>
      {offer.offerType === "careerCoach" ? (
        <CareerCoachDetails offer={offer} />
      ) : (
        ""
      )}
      {offer.offerType === "businessCoach" ? (
        <BusinessCoachDetails offer={offer} />
      ) : (
        ""
      )}
      {offer.offerType === "lifeCoach" ? (
        <LifeCoachDetails offer={offer} />
      ) : (
        ""
      )}
      {offer.offerType === "emotionalCoach" ? (
        <EmotionalCoachDetails offer={offer} />
      ) : (
        ""
      )}
      {offer.offerType === "budgetCoach" ? (
        <BudgetCoachDetails offer={offer} />
      ) : (
        ""
      )}
    </div>
  );
}

export default CoachDetails;
