import { Grid, Typography } from '@mui/material';
import React from 'react'
import AutocompleteInput from '../../../components/form/AutocompleteInput';
import TextareaAutoSize from '../../../components/form/TextareaAutoSize';
import TextInput from '../../../components/form/TextInput';

function CareerCoach({ t, control, inputErrors }) {
  const title = t('offers.offer_create.coach.request_career_coach');

  const workSituation = [
    { name: t('offers.offer_create.coach.work_situation_salaried'), value: 'salaried' },
    { name: t('offers.offer_create.coach.work_situation_salaried_outplacement'), value: 'salariedOutplacement' },
    { name: t('offers.offer_create.coach.work_situation_entrepreneur'), value: 'entrepreneur' },
    { name: t('offers.offer_create.coach.work_situation_looking_for_work'), value: 'lookingForWork' },
    { name: t('offers.offer_create.coach.work_situation_just_graduated_starter'), value: 'justGraduatedStarter' },
    { name: t('offers.offer_create.coach.work_situation_otherwise'), value: 'otherwise' }
  ];
  const gender = [
    { name: t('offers.offer_create.coach.gender_male'), value: 'male' },
    { name: t('offers.offer_create.coach.gender_female'), value: 'female' },
  ];
  const contactType = [
    { name: t('offers.offer_create.coach.contact_personal_contact'), value: 'personal' },
    { name: t('offers.offer_create.coach.contact_remote_contact'), value: 'remote' },
    { name: t('offers.offer_create.coach.contact_partly_person_partly_remotely'), value: 'partlyPersonAndRemotely' },
  ];
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  return (
    <div>
      <Typography
        variant="body1"
        style={{ paddingLeft: '1rem' }}
      >
        {title}
      </Typography>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="workSituation" labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_work_situation")}
              control={control}
              options={workSituation}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.workSituation?.value || inputErrors.workSituation || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <TextInput
              name="education" labelTheme={true}
              type="text"
              placeholder={t('offers.offer_create.coach.label_education_placeholder')}
              label={t("offers.offer_create.coach.label_education")}
              autoFocus={true}
              control={control}
              customError={inputErrors.education || null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="gender" labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_gender")}
              control={control}
              options={gender}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.gender?.value || inputErrors.gender || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <TextInput
              name="age" labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_age")}
              autoFocus={true}
              control={control}
              customError={inputErrors.age || null}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={8}>
            <TextareaAutoSize
              label={t("offers.offer_create.accountant.lable.explanation")}
              type="text" labelTheme={true}
              name="explanation"
              control={control}
              customError={inputErrors.explanation || null}
              placeholder={t("offers.offer_create.coach.explanation_career_coach").replace(/\/n/g, '\n')}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="deadline"
              type="text" labelTheme={true}
              label={t("offers.offer_create.website.deadline")}
              control={control}
              options={deadLine}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.deadline?.value || inputErrors.deadline || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="contactType"
              type="text" labelTheme={true}
              label={t("offers.offer_create.coach.label_contact_type")}
              control={control}
              options={contactType}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.contactType?.value || inputErrors.contactType || null
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}


export default CareerCoach