import { Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import API from "../config/axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setCountries } from "../features/common/commonSlice";
import { useAuth } from "../hooks/auth";
import { useTranslation } from "react-i18next";
import ApprovedOfferList from "./components/ApprovedOfferList";

// START:: List Of Offer Posted By User (With Edit And Update functionality)
// import OfferList from "./userOffer/OfferList";
// import EditOffer from "./userOffer/EditOffer";
// import DialogBox from "../components/DialogBox";
// ENDS:: List Of Offer Posted By User (With Edit And Update functionality)

function HomePage({ setIsNavbarOpen }) {
  // START:: List Of Offer Posted By User (With Edit And Update functionality)
  // const [editOfferId, setEditOfferId] = useState();
  // const handleClose = () =>{
  //   setEditOfferId(undefined)
  // }
  // const handleOpen = () =>{
  // }
  // ENDS:: List Of Offer Posted By User (With Edit And Update functionality)

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [filterOffer, setFilterOffer] = useState("all");
  const auth = useAuth();
  const userId = auth?.user?.id;

  const abortController = useRef(new AbortController());

  const getCountriesAuth = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    API.get("offer/countries", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          dispatch(setCountries(resData.data));
        } else {
          toast.error("Countries cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };
  const getCountries = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    API.get("/offer/country/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          dispatch(setCountries(resData.data));
        } else {
          toast.error("Countries cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };
  // Set Popup Box Children Component

  const setFilter = (filterType) => {
    if (filterType === filterOffer) {
      setFilterOffer("all");
    } else {
      setFilterOffer(filterType);
    }
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userId && userId !== "") {
      getCountriesAuth();
      return () => {
        abortController.current.abort();
      };
    }
    // eslint-disable-next-line
  }, [userId]);

  return (
    <div>
      <div className="banner">
        {/* <div className="titleTexts">
          <Typography variant="body1" align="center" className="title">
            {t("offers.home_page.banner.accounting_and_professionals")}
          </Typography>
          <Typography variant="body1" align="center" className="title">
            |
          </Typography>
          <Typography variant="body1" align="center" className="title">
            {t("offers.home_page.banner.discover_best_professionals")}
          </Typography>
        </div> */}
        <div>
          <Typography variant="h3" align="center" style={{ margin: "0px" }}>
            {t("offers.home_page.banner.get_quotes_in_2_minutes")}
          </Typography>
          <Typography variant="h3" align="center" style={{ margin: "0px" }}>
            {t("offers.home_page.banner.get_quotes_in_2_minutes_second")}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "max-content",
            margin: "1rem auto",
          }}
        >
          <div className="flex">
            <img src="/RoundCheck.png" alt="" />
            <Typography variant="h6" align="center">
              {t("offers.home_page.banner.get_free_quotes")}
            </Typography>
          </div>
          <div className="flex">
            <img src="/RoundCheck.png" alt="" />
            <Typography variant="h6" align="center">
              {t("offers.home_page.banner.multiple_quotes_within_24_hours")}
            </Typography>
          </div>
          <div className="flex">
            <img src="/RoundCheck.png" alt="" />
            <Typography variant="h6" align="center">
              {t("offers.home_page.banner.best_provider_free_quotes")}
            </Typography>
          </div>
        </div>
        <div className="titleTexts">
          <Button
            variant="contained"
            style={{ backgroundColor: "#D0AF88" }}
            onClick={() => setIsNavbarOpen(true)}
          >
            {t("offers.home_page.banner.request_free_quotes")}
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#D0AF88" }}
            onClick={() => {
              window.location.href = "https://dashbook.nl/login";
            }}
          >
            {t("offers.home_page.banner.sign_up_as_a_professional")}
          </Button>
        </div>
      </div>
      <div>
        <div
          className="buttonBox"
          style={{
            padding: "1rem 3rem",
            paddingTop: "0rem !important",
            overflowX: "scroll",
          }}
        >
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "accountant" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("accountant")}
          >
            <img
              src={"/Book.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.accountant")}
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "developer" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("developer")}
          >
            <img
              src={"/Website.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.website_apps_builder")}
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "graphicDesigner" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("graphicDesigner")}
          >
            <img
              src={"/GraphicDesigner.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.graphic_designer")}{" "}
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "dataAnalist" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("dataAnalist")}
          >
            <img
              src={"/Search.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.data_analyst")}
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "coach" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("coach")}
          >
            <img
              src={"/Book.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.coach")}
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign ${
              filterOffer === "advocate" ? "activeFilter" : ""
            }`}
            onClick={() => setFilter("advocate")}
          >
            <img
              src={"/Book.png"}
              alt="mail Icon"
              height={"16px"}
              style={{ margin: "auto 0px", marginRight: "5px" }}
            />
            {t("offers.header.advocate")}
          </Button>
        </div>
        <div className="offerListBox">
          <Typography className="tabHead" variant="h4">
            {t(
              "offers.home_page.offer_list.previous_quote_requests_on_dashbook"
            )}
          </Typography>
          <div id="offers">
            <ApprovedOfferList filterOffer={filterOffer} />
          </div>
        </div>
      </div>
      {
        // START:: List Of Offer Posted By User (With Edit And Update functionality)
      }
      {/* <div>
        <OfferList setEditOfferId={setEditOfferId} />
        {editOfferId && editOfferId !== "" && editOfferId !== null ? (
        <DialogBox 
          open={true}
          handleOpen={handleOpen}
          handleClose={handleClose}
          title={'Edit Offer'}
          component={<EditOffer id={editOfferId} />}
        />
      ) : (
        ""
      )}
      </div> */}
      {
        // END:: List Of Offer Posted By User (With Edit And Update functionality)
      }
    </div>
  );
}

export default HomePage;
