import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import TextareaAutoSize from "../../components/form/TextareaAutoSize";
import { highlightField } from "../../utils/Commons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import API from "../../config/axios";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "../../components/form/CheckBox";

const contactUsValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required Field"),
  subject: yup.string().required("Subject is required Field"),
  message: yup.string(),
  termsAndCondition: yup
    .boolean()
    .required("Terms and conditions must be accepted")
    .oneOf([true], "Terms and conditions must be accepted"),
  email: yup
    .string()
    .email("Please Enter Valid Email Id")
    .required("Email is required Field"),
});

const defaultValues = {
  name: null,
  subject: null,
  message: null,
  email: null,
};

function Contact() {
  const [inputErrors, setInputErrors] = useState({});
  const { t } = useTranslation();
  const mailController = useRef(new AbortController());
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(contactUsValidationSchema),
  });
  // Error Handler (Validation)
  const onError = (errors) => {
    try {
      if ("message" in errors) {
        console.log({ message: errors.message.message });
        setInputErrors({ message: errors.message.message });
      }
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error, "check");
      console.warn("Field cannot be highlighted");
    }
  };
  const contactUs = (formData) => {
    API.post(`/offer/sendmail/`, formData, {
      signal: mailController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          toast.success("Contact Us Form Submitted");
        } else {
          toast.error("Something Went Wrong At Sending contactUs Form Process");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Sending contactUs Form Process");
        }
      })
      .finally(() => {
        reset();
        navigate("/");
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    setInputErrors({});
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className="pageStart"
      style={{
        backgroundColor: "black",
        color: "white",
        borderBottom: "10px solid white",
      }}
    >
      <Grid
        container
        spacing={2}
        p={2}
        style={{ justifyContent: "space-evenly" }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sm={6}
          style={{
            textAlign: "center",
          }}
        ></Grid>
        <Grid item xs={0} md={7} sm={6}>
          <h1
            style={{
              textAlign: "left",
              marginBottom: "1rem",
            }}
          >
            {t("offers.contact_page.contact").toUpperCase()}
          </h1>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        p={2}
        style={{ justifyContent: "space-evenly" }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sm={6}
          style={{
            textAlign: "center",
          }}
        >
          <Grid item className="borderRadius" textAlign={"left !important"}>
            <img
              src={"/phoneIconBig.png"}
              alt="mail Icon"
              height={"50px"}
              style={{ margin: "auto 0px" }}
            />
            <h3 className="footerMenuTitle" style={{ marginTop: "0px" }}>
              {t("offers.contact_page.telephone")}
            </h3>
            <p className="footerAdd">
              {t("offers.contact_page.telephone_info")}
            </p>
            <Link
              className="footerLink"
              to={"tel:+0880000000"}
              style={{ justifyContent: "flex-start" }}
            >
              <Button
                variant="contained"
                style={{
                  marginTop: "1rem",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {t("offers.footer.phone_show")}
              </Button>
            </Link>
          </Grid>
          <Grid item className="borderRadius" textAlign={"left !important"}>
            <img
              src={"/mailIconBig.png"}
              alt="mail Icon"
              height={"50px"}
              style={{ margin: "auto 0px" }}
            />
            <h3 className="footerMenuTitle" style={{ marginTop: "0px" }}>
              {t("offers.contact_page.email")}
            </h3>
            <p className="footerAdd">{t("offers.contact_page.email_info")}</p>
            <Link
              className="footerLink"
              to="mailto:info@dashbookofferte"
              style={{ justifyContent: "flex-start" }}
            >
              <Button
                variant="contained"
                style={{
                  marginTop: "1rem",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {t("offers.footer.mail_add")}
              </Button>
            </Link>
          </Grid>
          <Grid item className="borderRadius" textAlign={"left !important"}>
            <img
              src={"/WhatsappIcon.png"}
              alt="WhatsappIcon Icon"
              height={"50px"}
              style={{ margin: "auto 0px" }}
            />
            <h3 className="footerMenuTitle" style={{ marginTop: "0px" }}>
              {t("offers.contact_page.whatsapp")}
            </h3>
            <p className="footerAdd">
              {t("offers.contact_page.whatsapp_info")}
            </p>
            <Link
              className="footerLink"
              to="/"
              style={{ justifyContent: "flex-start" }}
            >
              <Button
                variant="contained"
                style={{
                  marginTop: "1rem",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {t("offers.contact_page.whatsapp_btn")}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} sm={6} margin={"auto"}>
          <form autoComplete="off" onSubmit={handleSubmit(contactUs, onError)}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography
                  color="black"
                  fontSize="20px"
                  style={{
                    color: "white",
                    padding: "1rem",
                    paddingLeft: "0px",
                    fontSize: "1rem",
                  }}
                >
                  {t("offers.contact_page.any_questions_contact_us") + " "}
                  <Link
                    className="footerLink"
                    to={"/questions"}
                    style={{ display: "inline-block" }}
                  >
                    {t("offers.footer.frequently_questions") + "."}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item>
                  <TextInput
                    style={{ borderRadius: "5px" }}
                    name="name"
                    type="text"
                    label={t("offers.contact_page.your_name")}
                    autoFocus={true}
                    control={control}
                    customError={inputErrors.name || null}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: "black",
                        margin: "0px",
                        paddingTop: "5px",
                        paddingLeft: "10px",
                        color: "white",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item>
                  <TextInput
                    style={{ borderRadius: "5px" }}
                    name="email"
                    type="email"
                    label={t("offers.contact_page.email_address")}
                    control={control}
                    customError={inputErrors.email || null}
                    autoFocus={true}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: "black",
                        margin: "0px",
                        paddingTop: "5px",
                        paddingLeft: "10px",
                        color: "white",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item>
                  <TextInput
                    style={{ borderRadius: "5px" }}
                    name="subject"
                    type="text"
                    label={t("offers.contact_page.subject")}
                    autoFocus={true}
                    control={control}
                    customError={inputErrors.subject || null}
                    FormHelperTextProps={{
                      style: {
                        backgroundColor: "black",
                        margin: "0px",
                        paddingTop: "5px",
                        paddingLeft: "10px",
                        color: "white",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid item>
                  <TextareaAutoSize
                    label={t("offers.contact_page.message")}
                    type="text"
                    name="message"
                    rows={10}
                    control={control}
                    customError={inputErrors.message || null}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item>
                  <CheckBox
                    sx={{
                      color: "#FFFFFF",
                      "&.Mui-checked": {
                        color: "#FFFFFF",
                      },
                      paddingLeft: "0px",
                    }}
                    label={t("offers.offer_create.website.accept_condition")}
                    control={control}
                    name="termsAndCondition"
                    inputProps={{ "aria-label": "controlled" }}
                    customError={inputErrors.termsAndCondition || null}
                    errorStyle={{ color: "white" }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "green", color: "white" }}
                    sx={{ bgcolor: "green", color: "white" }}
                  >
                    {t("offers.contact_page.send")}
                    <img
                      alt=""
                      src={"./nextWhite.png"}
                      style={{ marginLeft: "10px" }}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contact;
