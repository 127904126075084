import { Grid, Typography } from '@mui/material';
import React from 'react'
import AutocompleteInput from '../../../components/form/AutocompleteInput';
import TextareaAutoSize from '../../../components/form/TextareaAutoSize';

function BusinessCoach({ t, control, inputErrors }) {
  const title = t('offers.offer_create.coach.request_business_coach');
  const helpType = [
    { name: t('offers.offer_create.coach.help_start_up_company'), value: 'startUpCompany' },
    { name: t('offers.offer_create.coach.help_business_growth'), value: 'businessGrowth' },
    { name: t('offers.offer_create.coach.help_professionalizing_my_company'), value: 'professionalizingCompany' },
    { name: t('offers.offer_create.coach.help_international_expansion'), value: 'internationalExpansion' },
    { name: t('offers.offer_create.coach.help_management_skills'), value: 'managementSkills' },
    { name: t('offers.offer_create.coach.help_other'), value: 'other' },

  ];
  const contactType = [
    { name: t('offers.offer_create.coach.contact_personal_contact'), value: 'personal' },
    { name: t('offers.offer_create.coach.contact_remote_contact'), value: 'remote' },
    { name: t('offers.offer_create.coach.contact_partly_person_partly_remotely'), value: 'partlyPersonAndRemotely' },
  ];
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];


  return (
    <div>
      <Typography
        variant="body1"
        style={{ paddingLeft: '1rem' }}
      >
        {title}
      </Typography>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              multiple={true} labelTheme={true}
              name="helpType"
              id="helpType"
              label={t("offers.offer_create.coach.label_help_type") +
              ` (${t("offers.offer_create.website.select_multiple")})`}
              type="text"
              defaultValue={[]}
              control={control}
              options={helpType}
              getOptionLabel={(option) => option.name}
              loading={false}
              customError={inputErrors.helpType || null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={8}>
            <TextareaAutoSize
              label={t("offers.offer_create.accountant.lable.explanation")}
              type="text" labelTheme={true}
              name="explanation"
              control={control}
              customError={inputErrors.explanation || null}
              placeholder={t("offers.offer_create.coach.explanation_business_coach").replace(/\/n/g, '\n')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="deadline"
              type="text" labelTheme={true}
              label={t("offers.offer_create.website.deadline")}
              control={control}
              options={deadLine}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.deadline?.value || inputErrors.deadline || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="contactType" labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_contact_type")}
              control={control}
              options={contactType}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.contactType?.value || inputErrors.contactType || null
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}


export default BusinessCoach