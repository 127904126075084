import React from "react";
import { useTranslation } from "react-i18next";

function AccountantDetails({ offer }) {
  const { t } = useTranslation();
  // Business Mode Options
  const businessForm = [
    {
      name: t("offers.offer_create.accountant.self_employed"),
      value: "selfEmployed",
    },
    {
      name: t("offers.offer_create.accountant.proprietorship"),
      value: "proprietorship",
    },
    {
      name: t("offers.offer_create.accountant.partnership_vof"),
      value: "partnershipVOF",
    },
    {
      name: t("offers.offer_create.accountant.partnership_maatschap"),
      value: "partnershipMaatschap",
    },
    { name: t("offers.offer_create.accountant.bv"), value: "partnershipBV" },
    {
      name: t("offers.offer_create.accountant.association_or_foundation"),
      value: "association",
    },
    {
      name: t("offers.offer_create.accountant.nonprofit_or_government"),
      value: "nonProfit",
    },
    {
      name: t("offers.offer_create.accountant.none_private"),
      value: "nonePrivate",
    },
    { name: t("offers.offer_create.accountant.other"), value: "other" },
  ];

  // Work Type Options
  const workType = [
    {
      name: t("offers.offer_create.accountant.complete_administration"),
      value: "completeAdministration",
    },
    {
      name: t("offers.offer_create.accountant.annual_accounts_and_tax_returns"),
      value: "annualAccTaxReturns",
    },
    {
      name: t("offers.offer_create.accountant.tax_returns"),
      value: "taxReturns",
    },
    {
      name: t("offers.offer_create.accountant.tax_advice"),
      value: "taxAdvice",
    },
    {
      name: t("offers.offer_create.accountant.auditor_report"),
      value: "auditorsReport",
    },
    {
      name: t("offers.offer_create.accountant.objection_letter"),
      value: "drawObjectionLetter",
    },
    {
      name: t("offers.offer_create.accountant.business_plan"),
      value: "drawBusinessPlan",
    },
    {
      name: t("offers.offer_create.accountant.other_activities"),
      value: "otherActivities",
    },
  ];

  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Deadlines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.business_form")}</p>
      <p className="detailsValue">{businessForm.find((item) => item.value === offer.businessForm).name}
      </p>
      <p className="detailsLabel">{t("offers.offer_create.website.what_work")}</p>
      <ul className="answers">
        {offer.workType.map((item) => (
          <li>{workType.find((work) => work.value === item).name}</li>
        ))}
      </ul>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.desired_location")}</p>
      <p className="detailsValue">{workMode.find((item) => item.value === offer.workMode).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
    </div>
  );
}

export default AccountantDetails;
