import React from "react";
import { useTranslation } from "react-i18next";

function CookiePolicy() {
  const { t } = useTranslation();
  return (
    <div className="pageStart" style={{ backgroundColor: "#F3F4F6" }}>
      <h1 style={{ textAlign: "center", marginBottom: "3rem" }}>
        {t("offers.cookie_policy_page.cookie_statement")}
      </h1>
      <div className="cardPage">
        <p>{t("offers.cookie_policy_page.welcome_to_dashbook_quote")}</p>
        <h3>{t("offers.cookie_policy_page.definition_of_cookies")}</h3>
        <p>{t("offers.cookie_policy_page.what_is_cookie")}</p>
        <h3>{t("offers.cookie_policy_page.functional_cookies")}</h3>
        <p>{t("offers.cookie_policy_page.dashbook_quotation_identifies")}</p>
        <h3>{t("offers.cookie_policy_page.analytical_cookies")}</h3>
        <p>
          {t("offers.cookie_policy_page.dashbook_quote_uses_google_analytics")}

          <ul>
            <li>
              {t(
                "offers.cookie_policy_page.data_processing_agreement_with_google"
              )}
            </li>
            <li>{t("offers.cookie_policy_page.ip_address_is_masked")}</li>
            <li>{t("offers.cookie_policy_page.data_sharing_is_disabled")}</li>
            <li>
              {t(
                "offers.cookie_policy_page.we_do_not_use_other_google_services"
              )}
            </li>
          </ul>
        </p>
        <h3>{t("offers.cookie_policy_page.permission")}</h3>
        <p>{t("offers.cookie_policy_page.data_protection_regulation")}</p>
        <h3>{t("offers.cookie_policy_page.other_unforeseen_cookies")}</h3>
        <p>{t("offers.cookie_policy_page.cookies_placed_by_third_parties")}</p>
        <h3>{t("offers.cookie_policy_page.browser_settings")}</h3>
        <p>{t("offers.cookie_policy_page.do_not_want_to_place_cookies")}</p>
      </div>
    </div>
  );
}

export default CookiePolicy;
