import React from "react";
import { useTranslation } from "react-i18next";

function EmotionalCoachDetails({ offer }) {
  const { t } = useTranslation();
  const gender = [
    { name: t("offers.offer_create.coach.gender_male"), value: "male" },
    { name: t("offers.offer_create.coach.gender_female"), value: "female" },
  ];
  const contactType = [
    {
      name: t("offers.offer_create.coach.contact_personal_contact"),
      value: "personal",
    },
    {
      name: t("offers.offer_create.coach.contact_remote_contact"),
      value: "remote",
    },
    {
      name: t(
        "offers.offer_create.coach.contact_partly_person_partly_remotely"
      ),
      value: "partlyPersonAndRemotely",
    },
  ];

  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  const helpTypeEmotional = [
    {
      name: t("offers.offer_create.coach.help_loosing_loved_one"),
      value: "loosingLovedOne",
    },
    { name: t("offers.offer_create.coach.help_trauma"), value: "trauma" },
    { name: t("offers.offer_create.coach.help_other"), value: "other" },
  ];

  const subCoachType = [
    {
      name: t("offers.offer_create.coach.coachsub_no_preference"),
      value: "noPreference",
    },
    {
      name: t("offers.offer_create.coach.coachsub_both_feet_and_ground"),
      value: "bothFeetAndGround",
    },
    {
      name: t("offers.offer_create.coach.coachsub_soft_matters"),
      value: "softMatters",
    },
  ];

  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_help_type")}</p>
      <p className="detailsValue">{helpTypeEmotional.find((item) => item.value === offer.helpType).name}
      </p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_sub_coach_type")}</p>
      <p className="detailsValue">{subCoachType.find((item) => item.value === offer.subCoachType).name}
      </p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_gender")}</p>
      <p className="detailsValue">{gender.find((item) => item.value === offer.gender).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_age")}</p>
      <p className="detailsValue">{offer.age}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_contact_type")}</p>
      <p className="detailsValue">{contactType.find((item) => item.value === offer.contactType).name}</p>
    </div>
  );
}

export default EmotionalCoachDetails;
