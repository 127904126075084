import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Box, Card, CardContent, Typography, Stack, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import API from "../config/axios";
import { useAuth } from "../hooks/auth";
import { toast } from "react-hot-toast";
import CustomBtn from "../components/CustomBtn";
import TextInput from "../components/form/TextInput";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const defaultValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export default function Login() {
  const auth = useAuth();
  const isDarkTheme = useTheme().palette.mode === "dark";
  const logoLink = isDarkTheme ? "/logo_light.svg" : "/logo.svg";

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const validationSchema = yup.object().shape({
    name: yup.string().max(30).required("name is required"),
    email: yup.string().required("email is required").email("email is invalid"),
    password: yup
      .string()
      .required("password is required")
      .min(8, "password must be at least 8 characters long"),
    confirmPassword: yup
      .string()
      .required("confirm password is required")
      .oneOf(
        [yup.ref("password")],
        "confirm password must be same as password"
      ),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const requestStartTime = Date.now();

    API.post("auth/professional/register", {
      name: data.name,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
    })
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          resData.user.tokens = resData.tokens;

          auth.login(resData.user);

          API.defaults.headers.common = {
            Authorization: "Bearer " + resData.tokens.access.token,
          };

          toast.success("Registered successfully");

          navigate("/", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
          } else {
            toast.error("Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  return (
    <>
      <img
        src={logoLink}
        width={"150px"}
        alt="logo"
        style={{ position: "relative", top: "30px", left: "30px" }}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack sx={{ marginX: 2, marginY: 10 }}>
          <Card
            elevation={1}
            sx={{
              p: 2,
              maxWidth: "450px",
              width: "min(90vw, 450px)",
              boxShadow: isDarkTheme
                ? "rgba(0, 0, 0, 0.1) 0px 10px 50px"
                : "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={"medium"} gutterBottom>
                      Register
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="name"
                      type="text"
                      label="Name"
                      autoFocus={true}
                      control={control}
                      placeholder="John doe"
                      customError={inputErrors.name || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="email"
                      type="email"
                      label="Email Address"
                      control={control}
                      placeholder="demo@company.com"
                      customError={inputErrors.email || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="password"
                      type="password"
                      label="Password"
                      control={control}
                      customError={inputErrors.password || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                      control={control}
                      customError={inputErrors.confirmPassword || null}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <CustomBtn
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="large"
                      disableRipple
                      type="submit"
                      loading={isLoading}
                    >
                      Register
                    </CustomBtn>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <span>
                        <Typography display={"inline"} variant="body2" mr={1}>
                          Already have an account ?
                        </Typography>
                        <Link to="/login" className="custom-link">
                          <Typography
                            color="secondary"
                            sx={{ cursor: "pointer" }}
                            display={"inline"}
                            variant="body2"
                          >
                            Login
                          </Typography>
                        </Link>
                      </span>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </>
  );
}
