import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer({ setIsNavbarOpen, setTitle, setComponent, setOpen }) {
  const { t } = useTranslation();
  const manageDialogComponent = (type) => {
    setIsNavbarOpen(false);
    let title;
    switch (type) {
      case "Website":
        title = t("offers.offer_create.website.page_title");
        break;
      case "Advocate":
        title = t("offers.offer_create.advocate.page_title");
        break;
      case "Coach":
        title = t("offers.offer_create.coach.page_title");
        break;
      case "DataAnalyst":
        title = t("offers.offer_create.data_analyst.page_title");
        break;
      case "GraphicDesigner":
        title = t("offers.offer_create.graphic_designer.page_title");
        break;
      case "Accountant":
        title = t("offers.offer_create.accountant.page_title");
        break;
      default:
        break;
    }

    setTitle(title);
    setComponent(`${type}`);
    setOpen(true);
  };
  return (
    <>
      <div className="p-1 footer">
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <Link
              className="footerLink"
              to={"/"}
              style={{ marginBottom: "0px" }}
            >
              <img
                src={"/logo_light.svg"}
                alt="Dashbook Logo"
                width={"100px"}
                height={"15px"}
                style={{ margin: "auto 0px" }}
              />
              <h3 className="footerMenuTitle">{t("offers.footer.offers")}</h3>
            </Link>
            <p
              style={{
                color: "white",
                marginTop: "0px",
                textAlign: "left",
              }}
            >
              {t("offers.footer.find_the_perfect_match")}
            </p>
            <Link
              className="footerLink"
              to="mailto:info@dashbookofferte"
              style={{ justifyContent: "flex-start" }}
            >
              <img
                src={"/mailIcon.png"}
                alt="mail Icon"
                height={"16px"}
                style={{ margin: "auto 0px" }}
              />
              <span style={{ margin: "auto 0px" }}>
                {t("offers.footer.mail_add")}
              </span>
            </Link>
            <Link
              className="footerLink"
              to={"tel:+0880000000"}
              style={{ justifyContent: "flex-start" }}
            >
              <img
                src={"/phoneIcon.png"}
                alt="phone Icon"
                height={"16px"}
                style={{ margin: "auto 0px" }}
              />
              <span style={{ margin: "auto 0px" }}>
                {t("offers.footer.phone_show")}
              </span>
            </Link>
            <h3 className="footerMenuTitle">{t("offers.footer.locations")}</h3>
            <p className="footerAdd">{t("offers.footer.netherlands")}</p>
            <p className="footerAdd">{t("offers.footer.keersopstraat")}</p>
            <p className="footerAdd">{t("offers.footer.rotterdam")}</p>
            <p className="footerAdd">
              {t("offers.footer.chamber_of_commerce")}
            </p>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">
              {t("offers.footer.over_offerti")}
            </h3>
            <Link className="footerLink" to={"/contact"}>
              {t("offers.footer.contact_title")}
            </Link>
            <Link className="footerLink" to={"/about"}>
              {t("offers.footer.about_dashbook_quote")}
            </Link>
            <Link className="footerLink" to={"/work"}>
              {t("offers.footer.how_does_dashbook_quote")}
            </Link>
            <Link className="footerLink" to={"/questions?number=12"}>
              {t("offers.footer.frequently_questions")}
            </Link>
            <Link className="footerLink" to={"/conditions"}>
              {t("offers.footer.terms_conditions")}
            </Link>
            <Link className="footerLink" to={"/privacy"}>
              {t("offers.footer.privacy_declaration")}
            </Link>
            <Link className="footerLink" to={"/cookie"}>
              {t("offers.footer.cookies")}
            </Link>
            <Link className="footerLink" to={"/sitemap"}>
              {t("offers.footer.sitemap_app")}
            </Link>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">{t("offers.footer.categories")}</h3>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("Accountant")}
            >
              {t("offers.header.accountant")}
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("Website")}
            >
              {t("offers.header.website_apps_builder")}
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("GraphicDesigner")}
            >
              {t("offers.header.graphic_designer")}
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("DataAnalyst")}
            >
              {t("offers.header.data_analyst")}
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("Coach")}
            >
              {t("offers.header.coach")}
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              onClick={() => manageDialogComponent("Advocate")}
            >
              {t("offers.header.advocate")}
            </Link>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">
              {t("offers.footer.for_companies")}
            </h3>
            <Link
              className="footerLink"
              onClick={() => {
                window.location.href = "https://dashbook.nl/login";
              }}
            >
              {t("offers.footer.register_as_professional")}
            </Link>
            {/* <Link className="footerLink" to={"/directory"}>
            {t("offers.footer.directory")}
          </Link> */}
            <Link className="footerLink" to={"/buycredits"}>
              {t("offers.footer.buy_credits")}
            </Link>
          </Grid>
        </Grid>
      </div>
      <p
        style={{
          textAlign: "left",
          margin: "0px",
          padding: "1rem 3rem",
          backgroundColor: "black",
          color: "white",
        }}
      >
        {t("offers.footer.copyright")}
      </p>
    </>
  );
}

export default Footer;
