import React, { useRef, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialogBox from "../../../components/CustomDialogBox";
import API from "../../../config/axios";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import DoPaymentDialogBox from "./doPayment";
import useDialogHooks from "../../../components/Hooks/useDialogHooks";

const BuyCreditsDialogBox = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const abortController = useRef(new AbortController());
  const [creditBuy, setCreditBuy] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState();
  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();

  // ** Table header
  const headCells = [
    {
      id: "price",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.amount"),
      sortable: true,
    },
    {
      id: "credits",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.credits"),
      sortable: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.credits.actions"),
      sortable: false,
    },
  ];

  // ** Pagination handler
  const showNoData = !isLoading && rows.length === 0 ? true : false;
  const totalColumns = headCells.length + 1;

  // ** API services for credit list
  const getCreditsList = (searchVal = "") => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);

    API.get("/offer/creditlist", {
      params: {
        pagination: false,
        // page: page + 1,
        // rowsPerPage: rowsPerPage,
        // sortBy: orderBy,
        // descending: order === "asc" ? false : true,
        filter: searchVal,
      },
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setRows(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCreditsList("");

    let user = localStorage.getItem("dashbook_user")
      ? JSON.parse(localStorage.getItem("dashbook_user"))
      : {};

    setUserId(user.id);

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomDialogBox
        title={t("offer.professional.credits.credits")}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Grid container spacing={2}>
          <Paper
            sx={{
              backgroundColor: "primaryBg.light",
              // width: "max-content",
              margin: "1rem auto",
              width: "100%",
            }}
            // className={styles.custom_container}
            // elevation={2}
          >
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ borderRadius: "inherit" }}
            >
              <Table
                aria-label="custom pagination table"
                className={"custom-table br-0"}
                sx={{ backgroundColor: "primaryBg.light" }}
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sx={{ textAlign: "center" }}
                        style={{ width: "150px" }}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell scope="row" sx={{ textAlign: "center" }}>
                        {row.amount?.toLocaleString("nl-NL", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </TableCell>
                      <TableCell scope="row" sx={{ textAlign: "center" }}>
                        {row.credits?.toLocaleString("nl-NL", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            size="large"
                            disableRipple
                            loading={false}
                            onClick={() => {
                              setCreditBuy(row);
                              openDialog("showBuyCreditsConfirmBox");
                            }}
                          >
                            {t("offer.professional.credits.buyNow")}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}

                  {showNoData && (
                    <TableRow>
                      <TableCell colSpan={totalColumns}>
                        <Typography
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            color: "grey",
                          }}
                          variant="body2"
                        >
                          No Data Available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </CustomDialogBox>
      {isDialogOpen("showBuyCreditsConfirmBox") && (
        <DoPaymentDialogBox
          isOpen={() => isDialogOpen("showBuyCreditsConfirmBox")}
          onClose={() => closeDialog("showBuyCreditsConfirmBox")}
          creditBuy={creditBuy}
          userId={userId}
        />
      )}
    </>
  );
};

export default BuyCreditsDialogBox;
