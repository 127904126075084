import dayjs from "../config/dayjs";
import * as XLSX from "xlsx";

function findKeyWithMessage(errorObj, parentKey = "") {
  if (typeof errorObj === "object" && errorObj !== null) {
    for (const key in errorObj) {
      if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof errorObj[key] === "object" && errorObj[key] !== null) {
          if ("message" in errorObj[key]) {
            return currentKey;
          }
          const result = findKeyWithMessage(errorObj[key], currentKey);
          if (result) {
            return result;
          }
        } else if (Array.isArray(errorObj[key])) {
          for (let i = 0; i < errorObj[key].length; i++) {
            const result = findKeyWithMessage(
              errorObj[key][i],
              `${currentKey}[${i}]`
            );
            if (result) {
              return result;
            }
          }
        }
      }
    }
  }
  return null;
}

function convertTimeToNumber(timeStr) {
  // Split the time string into hours, minutes, and am/pm indicator
  const [time, meridian] = timeStr.split(" ");

  // Split hours and minutes
  const [hoursStr, minutesStr] = time.split(":");

  // Convert hours and minutes to integers
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  // Calculate the total minutes
  let totalMinutes = hours * 60 + minutes;

  // Adjust for am/pm
  if (meridian.toLowerCase() === "pm" && hours !== 12) {
    totalMinutes += 12 * 60;
  } else if (meridian.toLowerCase() === "am" && hours === 12) {
    totalMinutes -= 12 * 60;
  }

  return totalMinutes;
}

function convertTimeInNumberToDayjs(timeInMinutes) {
  // Calculate hours and minutes from the total minutes
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;

  // Create a Day.js object with the current date and the specified time
  const dayjsObject = dayjs().set("hour", hours).set("minute", minutes);

  return dayjsObject;
}

function convertDateToDayjs(date) {
  // Create a Day.js object with the specified date
  const dayjsObject = dayjs(date);

  return dayjsObject;
}

function highlightField(fieldId) {
  try {
    const fieldElem = document.getElementById(fieldId);

    let headerOffset = 100;
    let elementPosition = fieldElem.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } catch (error) {
    console.warn("Field cannot be highlighted");
  }
}

/**
 * Get all dates between start and end date
 *
 * @param {String} startDate - e.g. 2023-07-01
 * @param {String} endDate - e.g. 2023-07-31
 * @returns {Map}
 */
function getAllDatesBetween(startDate, endDate) {
  const dates = new Map();
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const daysDiff = end.diff(start, "day");

  for (let i = 0; i <= daysDiff; i++) {
    dates.set(start.add(i, "day").format("YYYY-MM-DD"), {
      hoursDocs: [],
      totalTimeInMinutes: 0,
    });
  }

  return dates;
}

function convertMinutesToTime(totalMinutes) {
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;

  minutes = minutes.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

function convertAmountToPercentage(amount, baseAmount = 0) {
  if (isNaN(amount) || !amount) {
    return "";
  }

  if (isNaN(baseAmount) || !baseAmount) {
    return "";
  }

  return ((Number(amount) * 100) / Number(baseAmount)).toLocaleString("nl-NL", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
}

function convertPercentageToAmount(percentage, baseAmount = 0) {
  if (isNaN(percentage) || !percentage) {
    return "";
  }

  if (isNaN(baseAmount) || !baseAmount) {
    return "";
  }

  return ((Number(percentage) * Number(baseAmount)) / 100).toLocaleString(
    "nl-NL",
    {
      style: "decimal",
      minimumFractionDigits: 2,
    }
  );
}

function objectToFormData(formData, data, parentKey) {
  if (data instanceof FileList) {
    // Handle FileList array
    for (let i = 0; i < data.length; i++) {
      formData.append(`${parentKey}[${i}]`, data[i]);
    }
  } else if (Array.isArray(data)) {
    // Handle arrays
    data.forEach((item, index) => {
      const key = `${parentKey}[${index}]`;
      objectToFormData(formData, item, key);
    });
  } else if (typeof data === "object" && data !== null) {
    // Handle nested objects
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        objectToFormData(formData, data[key], newKey);
      }
    }
  } else {
    // Handle normal data
    formData.append(parentKey, data);
  }
}

function getDaysAgo(date) {
  date = new Date(date);
  const now = new Date();
  const diffTime = now - date;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 1) {
    return "1 ";
  } else {
    return `${diffDays} `;
  }
}

function getMonthAndYear(startDate, endDate) {
  if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const startMonth = start.format("MMM");
    const endMonth = end.format("MMM");

    const startDay = start.format("D");
    const endDay = end.format("D");

    const startYear = start.format("YYYY");
    const endYear = end.format("YYYY");

    let result = "";

    if (startMonth.toUpperCase() === endMonth.toUpperCase()) {
      if (startDay === "1" && endDay === end.endOf("month").format("D")) {
        result = `${startMonth}-${startYear}`;
      } else if (startDay === endDay) {
        result = `${startDay}-${startMonth}-${startYear}`;
      } else {
        result = `${startDay}-${endDay} ${startMonth}-${startYear}`;
      }
    } else {
      result = `${startDay}-${endDay} ${startMonth}-${startYear} --- ${endMonth}-${endYear}`;
    }

    return result;
  } else {
    return "";
  }
}

function getStatusColor(status) {
  let color;
  switch (status) {
    case "concept":
      color = "default";
      break;
    case "invoiced":
      color = "info";
      break;
    case "planned":
      color = "info";
      break;
    case "sent":
      color = "secondary";
      break;
    case "expired":
      color = "error";
      break;
    case "remembered":
      color = "default";
      break;
    case "paid":
      color = "success";
      break;
    case "irrecoverable":
      color = "primary";
      break;
    case "offer_accepted":
      color = "success";
      break;
    case "overpaid":
      color = "info";
      break;
    case "underpaid":
      color = "error";
      break;
    default:
      color = "default"; // Default color for unknown status
  }
  return color;
}

function FormateRowData(dataType, formate, value) {
  switch (dataType) {
    case "Date":
      return dayjs(value).format(formate);
    case "Currency":
      return value.toLocaleString("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
    case "isAmountInclusiveOfVat":
      return value === true ? "Inclusive" : "Exclusive";
    default:
      return value;
  }
}

function FormateDataForExcel(ColumnData, RowData) {
  if (!ColumnData?.length || !RowData?.length) return [];

  return RowData.map((row) => {
    const formattedRow = {};
    ColumnData.forEach((col) => {
      const property = row[col.dataField] || row[col.dataField2];
      if (property !== undefined) {
        formattedRow[col.label] = col.innerKey
          ? FormateRowData(col.dataType, col.format, property[col.innerKey])
          : FormateRowData(col.dataType, col.format, property);
      }
    });

    return formattedRow;
  });
}

const formatCurrency = (value, decimal) => {
  const options = {
    style: "currency",
    currency: "EUR",
  };

  if (decimal) {
    return value?.toLocaleString("nl-NL", options);
  } else {
    const roundedValue = value !== undefined ? Math.round(value) : 0;
    options.maximumFractionDigits = 0;
    return roundedValue?.toLocaleString("nl-NL", options);
  }
};

function excelDataForZip(excelData, fileName) {
  const fileExtension = ".xlsx";

  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(excelData);
  XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
  let wbout = XLSX.write(workbook, {
    bookType: "xlsx",
    bookSST: true,
    type: "binary",
  });
  const excelFileName = fileName + fileExtension;

  return { wbout, excelFileName };
}

function convertObjectToFormData(dataObject) {
  const formData = new FormData();

  // Iterate over the object keys
  for (const key in dataObject) {
    // Check if the property belongs to the object and not its prototype chain
    if (dataObject.hasOwnProperty(key)) {
      // Append each key-value pair to the FormData object
      formData.append(key, dataObject[key]);
    }
  }

  return formData;
}

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
  const truncatedText =
    lastSpaceIndex > 0
      ? text.substring(0, lastSpaceIndex) + "..."
      : text.substring(0, maxLength) + "...";

  return truncatedText;
};

function decodeHTMLEntities(input) {
  return input.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export {
  findKeyWithMessage,
  highlightField,
  convertTimeToNumber,
  convertTimeInNumberToDayjs,
  convertDateToDayjs,
  getAllDatesBetween,
  convertMinutesToTime,
  convertAmountToPercentage,
  convertPercentageToAmount,
  objectToFormData,
  getDaysAgo,
  getMonthAndYear,
  getStatusColor,
  FormateDataForExcel,
  excelDataForZip,
  convertObjectToFormData,
  truncateText,
  decodeHTMLEntities,
  formatCurrency,
  isEmptyObject,
};
