export const getLatitudeLongitude = (place, inputName, setValue) => {
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ address: place }, (results, status) => {
    if (status === "OK" && results.length > 0) {
      const latitude = results[0].geometry.location.lat();
      const longitude = results[0].geometry.location.lng();
      setValue(inputName, { latitude, longitude });
    } else {
      console.log(
        "error",
        "Geocode was not successful for the following reason: " + status
      );
    }
  });
};
export const getCurrentLocation = async () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const result = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(result); // Resolve the promise with the location
        },
        (error) => {
          console.error("Geolocation error:", error.message);
          reject(error); // Reject the promise with the error
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      reject(new Error("Geolocation is not supported")); // Reject the promise if geolocation is not supported
    }
  });
};
