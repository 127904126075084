import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function HowItWorks({ setIsNavbarOpen }) {
  const { t } = useTranslation();

  return (
    <div
      className="pageStart"
      // style={{ paddingRight: "3rem", paddingLeft: "3rem" }}
    >
      <Grid
        container
        sx={{ margin: "1rem 0px" }}
        justifyContent={"space-evenly"}
      >
        <Grid item xs={12} md={5} sm={12} style={{ marginBottom: "2rem" }}>
          <Card
            style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          >
            <CardContent>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.dashbook_quote_work")}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  textDecoration: "underline",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                {t("offers.how_it_works_page.for_clients")}
              </Typography>
              <Typography
                sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                variant="subtitle1"
              >
                {t("offers.how_it_works_page.post_your_order")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.describe_your_project")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.choose_categories")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.accounting")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.web_development")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.translations")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.copywriting")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.secretariat")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.coaching")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.mediation")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.much_more")}
              </Typography>
              <Typography
                sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                variant="subtitle1"
              >
                {t("offers.how_it_works_page.get_quotes")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.within_24_hours_receive_quotes")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.compare_quotes")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.price")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.quality")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.experience")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.portfolio")}
              </Typography>
              <Typography
                sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                variant="subtitle1"
              >
                {t("offers.how_it_works_page.perfect_match")}
              </Typography>
              <Typography variant="subtitle1">
                {t(
                  "offers.how_it_works_page.choose_the_professional_suits_your_project"
                )}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.who_you_want_to_work_with")}
              </Typography>
              <Typography variant="subtitle1">
                {t("offers.how_it_works_page.placing_order_free_of_charge")}
              </Typography>

              <Link
                onClick={() => setIsNavbarOpen(true)}
                style={{ textDecoration: "none" }}
              >
                <div className="flex" style={{ paddingTop: "1rem" }}>
                  <img alt="" width={"16px"} src={"./CaretDoubleRight.png"} />
                  <div className="textLine">
                    <Typography
                      variant="body1"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        color: "#D0AF88",
                      }}
                    >
                      {t("offers.how_it_works_page.request_quotes")}
                    </Typography>
                  </div>
                </div>
              </Link>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={5} sm={12} style={{ marginBottom: "2rem" }}>
          <Card
            sx={{ height: "100%" }}
            style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              sx={{ height: "100%" }}
            >
              <div>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.dashbook_quote_work")}{" "}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  {t("offers.how_it_works_page.for_professional")}
                </Typography>
                <Typography
                  sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                  variant="subtitle1"
                >
                  {t("offers.how_it_works_page.sign_up_for_free")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.register_your_profile")}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    "offers.how_it_works_page.categories_relevant_to_your_field"
                  )}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.no_subscription_costs")}
                </Typography>
                <Typography
                  sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                  variant="subtitle1"
                >
                  {t("offers.how_it_works_page.receive_relevant_commands")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.receive_daily_notifications")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.commands_you_want_to_respond")}
                </Typography>

                <Typography
                  sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                  variant="subtitle1"
                >
                  {t("offers.how_it_works_page.submit_quotes")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.pay_per_quote_you_make")}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    "offers.how_it_works_page.you_have_full_control_over_your_costs"
                  )}
                </Typography>
                <Typography
                  sx={{ color: "#D0AF88", padding: "1rem 0px" }}
                  variant="subtitle1"
                >
                  {t("offers.how_it_works_page.new_customers")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.grow_your_customer_base")}
                </Typography>
                <Typography variant="subtitle1">
                  {t("offers.how_it_works_page.long_term_relationships")}
                </Typography>
              </div>
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <div className="flex" style={{ paddingTop: "1rem" }}>
                  <img alt="" width={"16px"} src={"./CaretDoubleRight.png"} />
                  <div className="textLine">
                    <Typography
                      variant="body1"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        color: "#D0AF88",
                      }}
                    >
                      {t("offers.how_it_works_page.sign_up_as_a_professional")}
                    </Typography>
                  </div>
                </div>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default HowItWorks;
