import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Faq() {
  const { t } = useTranslation();
  const location = useLocation();
  const targetRef = useRef(null);
  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryNumber = queryParams.get("number");
    if (queryNumber && !isNaN(queryNumber)) {
      setExpandedPanel(`faq-header-${queryNumber}`);
      if (queryNumber === "12") scrollToTarget();
    } else {
      setExpandedPanel("faq-header-1");
    }
  }, [location.search]);

  return (
    <div className="pageStart" style={{ padding: "4rem" }}>
      <Typography variant="h5" p={2}>
        {t("offers.faq_page.general")}
      </Typography>
      {/* Question 1 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-1"}
        onChange={handleChange("faq-header-1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-1"
          id="faq-header-1"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_1")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_1_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_1_2")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_1_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography variant="h5" p={2}>
        {t("offers.faq_page.client")}
      </Typography>
      {/* Question 2 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-2"}
        onChange={handleChange("faq-header-2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-2"
          id="faq-header-2"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_2")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{t("offers.faq_page.answer_2")}</Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 3 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-3"}
        onChange={handleChange("faq-header-3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-3"
          id="faq-header-3"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_3")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_3_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_3_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 4 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-4"}
        onChange={handleChange("faq-header-4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-4"
          id="faq-header-4"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_4")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_4_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_4_2")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_4_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 5 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-5"}
        onChange={handleChange("faq-header-5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-5"
          id="faq-header-5"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_5")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_5_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_5_2")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_5_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 6 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-6"}
        onChange={handleChange("faq-header-6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-6"
          id="faq-header-6"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_6")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_6_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_6_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 7 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-7"}
        onChange={handleChange("faq-header-7")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-7"
          id="faq-header-7"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_7")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_7_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_7_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 8 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-8"}
        onChange={handleChange("faq-header-8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-8"
          id="faq-header-8"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_8")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_2")}
          </Typography>{" "}
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_3")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_4")}
          </Typography>{" "}
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_5")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_8_6")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 9 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-9"}
        onChange={handleChange("faq-header-9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-9"
          id="faq-header-9"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_9")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_9_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_9_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography variant="h5" p={2} ref={targetRef}>
        {t("offers.faq_page.professional")}
      </Typography>
      {/* Question 10 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-10"}
        onChange={handleChange("faq-header-10")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-10"
          id="faq-header-10"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_10")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_10_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_10_2")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_10_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 11 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-11"}
        onChange={handleChange("faq-header-11")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-11"
          id="faq-header-11"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_11")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_11_1")}
          </Typography>
          <ol>
            <li>{t("offers.faq_page.answer_11_list_1")}</li>
            <li>{t("offers.faq_page.answer_11_list_2")}</li>
            <li>{t("offers.faq_page.answer_11_list_3")}</li>
          </ol>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_11_2")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_11_3")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 12 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-12"}
        onChange={handleChange("faq-header-12")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-12"
          id="faq-header-12"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_12")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_12_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_12_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 13 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-13"}
        onChange={handleChange("faq-header-13")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-13"
          id="faq-header-13"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_13")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_13_1")}
          </Typography>
          <ol>
            <li>{t("offers.faq_page.answer_13_list_1")}</li>
            <li>{t("offers.faq_page.answer_13_list_2")}</li>
            <li>{t("offers.faq_page.answer_13_list_3")}</li>
            <li>{t("offers.faq_page.answer_13_list_4")}</li>
          </ol>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_13_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 14 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-14"}
        onChange={handleChange("faq-header-14")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-14"
          id="faq-header-14"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_14")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_14")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 15 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-15"}
        onChange={handleChange("faq-header-15")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-15"
          id="faq-header-15"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_15")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_15_1")}
          </Typography>
          <ol>
            <li>{t("offers.faq_page.answer_15_list_1")}</li>
            <li>{t("offers.faq_page.answer_15_list_2")}</li>
            <li>{t("offers.faq_page.answer_15_list_3")}</li>
          </ol>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_15_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 16 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-16"}
        onChange={handleChange("faq-header-16")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-16"
          id="faq-header-16"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_16")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_16")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 17 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-17"}
        onChange={handleChange("faq-header-17")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-17"
          id="faq-header-17"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_17")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_17")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 18 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-18"}
        onChange={handleChange("faq-header-18")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-18"
          id="faq-header-18"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_18")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_18_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_18_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 19 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-19"}
        onChange={handleChange("faq-header-19")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-19"
          id="faq-header-19"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_19")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_19_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_19_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 20 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-20"}
        onChange={handleChange("faq-header-20")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-20"
          id="faq-header-20"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_20")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_20")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 21 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-21"}
        onChange={handleChange("faq-header-21")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-21"
          id="faq-header-21"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_21")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_21")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 22 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-22"}
        onChange={handleChange("faq-header-22")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-22"
          id="faq-header-22"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_22")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_22")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 23 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-23"}
        onChange={handleChange("faq-header-23")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-23"
          id="faq-header-23"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_23")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_23_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_23_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 24 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-24"}
        onChange={handleChange("faq-header-24")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-24"
          id="faq-header-24"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_24")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_24_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_24_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 25 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-25"}
        onChange={handleChange("faq-header-25")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-25"
          id="faq-header-25"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_25")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_25_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_25_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 26 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-26"}
        onChange={handleChange("faq-header-26")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-26"
          id="faq-header-26"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_26")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_26_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_26_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 27 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-27"}
        onChange={handleChange("faq-header-27")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-27"
          id="faq-header-27"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_27")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_27_1")}
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_27_2")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography variant="h5" p={2}>
        {t("offers.faq_page.other")}
      </Typography>
      {/* Question 28 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-28"}
        onChange={handleChange("faq-header-28")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-28"
          id="faq-header-28"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_28")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_28")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 29 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-29"}
        onChange={handleChange("faq-header-29")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-29"
          id="faq-header-29"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_29")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_29")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 30 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-30"}
        onChange={handleChange("faq-header-30")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-30"
          id="faq-header-30"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_30")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_30")}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Question 31 */}
      <Accordion
        sx={{ border: "1px solid #D0AF88" }}
        expanded={expandedPanel === "faq-header-31"}
        onChange={handleChange("faq-header-31")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="faq-content-31"
          id="faq-header-31"
        >
          <Typography variant="subtitle1">
            {t("offers.faq_page.question_31")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>
            {t("offers.faq_page.answer_31")}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Faq;
