import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Sitemap() {
  const { t } = useTranslation();

  const sitemapStyles = {
    body: {
      fontFamily: "Arial, sans-serif",
      backgroundColor: "#ffffff",
      margin: 0,
      padding: 0,
    },
    container: {
      margin: "0 auto",
    },
    ul: {
      listStyleType: "none",
      padding: 0,
    },
    li: {
      marginBottom: "10px",
    },
    a: {
      color: "#333333",
      textDecoration: "none",
    },
    aHover: {
      color: "#D0AF88 !important",
      textDecoration: "underline",
    },
    h2: {
      color: "#D0AF88",
      marginTop: "0px",
      textAlign: "center",
      fontSize: "1.3rem",
    },
  };

  return (
    <div className="pageStart">
      <Grid container spacing={2} columns={12} mt={"0px"}>
        <Grid item xs={12} md={3} sm={12}>
          <h2 style={sitemapStyles.h2}>{t("offers.sitemap_page.overview")}</h2>
          <ul id="progress">
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/#offers"}
              >
                {t("offers.offer_list")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/contact"}
              >
                {t("offers.footer.contact_title")}
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={5} sm={12}>
          <h2 style={sitemapStyles.h2}>
            {t("offers.sitemap_page.information")}
          </h2>
          <ul id="progress">
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/about"}
              >
                {t("offers.footer.about_dashbook_quote")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/work"}
              >
                {t("offers.footer.how_does_dashbook_quote")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/questions"}
              >
                {t("offers.footer.frequently_questions")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/conditions"}
              >
                {t("offers.footer.terms_conditions")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/privacy"}
              >
                {t("offers.footer.privacy_declaration")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/cookie"}
              >
                {t("offers.footer.cookies")}
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} sm={12}>
          <h2 style={sitemapStyles.h2}>{t("offers.sitemap_page.navigate")}</h2>
          <ul id="progress">
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/sitemap"}
              >
                {t("offers.footer.sitemap_app")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                onClick={() => {
                  window.location.href = "https://dashbook.nl/login";
                }}
              >
                {t("offers.footer.register_as_professional")}
              </Link>
            </li>
            <li className="list">
              <div class="divider grey"></div>
            </li>
            <li className="list">
              <div class="node grey"></div>
              <Link
                className="footerLink"
                style={{ color: "black" }}
                to={"/buycredits"}
              >
                {t("offers.footer.buy_credits")}
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default Sitemap;
