import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import API from "../../config/axios";
import { useAuth } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
import styles from "../../css/common.module.css";
import CustomBtn from "../../components/CustomBtn";
import DialogBox from "../../components/DialogBox";

function BuyCredits() {
  const creditController = useRef(new AbortController());
  const { t } = useTranslation();
  const auth = useAuth();
  const userId = auth?.user?.id;
  const navigate = useNavigate();

  if (!userId) {
    navigate("/login");
  }

  // ** State managements

  const [rows, setRows] = useState([]);
  const abortController = useRef(new AbortController());
  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [creditBuy, setCreditBuy] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const convertAmount = (amountInRupees) => {
    const amountFloat = parseFloat(amountInRupees);

    if (isNaN(amountFloat)) {
      // return "Invalid input";
      return null;
    }

    if (Number.isInteger(amountFloat)) {
      return amountFloat.toFixed(2);
    } else {
      return amountFloat.toFixed(2).replace(/\.?0*$/, ""); // Remove trailing zeros
    }
  };

  // ** Table header
  const headCells = [
    {
      id: "price",
      numeric: false,
      disablePadding: true,
      label: t("offers.buy_credits_page.amount"),
      sortable: true,
    },
    {
      id: "credits",
      numeric: false,
      disablePadding: true,
      label: t("offers.buy_credits_page.credits"),
      sortable: true,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: t("offers.buy_credits_page.actions"),
      sortable: false,
    },
  ];

  // ** Pagination handler
  const showNoData = !isLoading && rows.length === 0 ? true : false;
  const totalColumns = headCells.length + 1;

  // ** API services for credit list
  const getCreditsList = (searchVal = "") => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);

    API.get("/offer/creditlist", {
      params: {
        pagination: false,
        // page: page + 1,
        // rowsPerPage: rowsPerPage,
        // sortBy: orderBy,
        // descending: order === "asc" ? false : true,
        filter: searchVal,
      },
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setRows(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showBuyConfirmationDialog = () => {
    setShowBuyDialog(true);
  };

  const closeBuyConfirmationDialog = () => {
    setShowBuyDialog(false);
  };

  const doPayment = async () => {
    setIsLoading(true);
    closeBuyConfirmationDialog();

    if (Object.keys(creditBuy).length > 0) {
      const amount = convertAmount(creditBuy.amount);
      const order_id = v4();
      creditController.current.abort();
      creditController.current = new AbortController();

      const data = {
        userId: userId,
        creditId: creditBuy.id,
        amount: {
          currency: "EUR",
          value: amount,
        },
        description: `Buy Credit For UserId: ${userId}, Amount: ${amount} And Order Id: ${order_id}`,
        redirectUrl: window.location.href,
        webhookUrl: `${
          API.getUri() || API.defaults.baseURL
        }offer/creditstatus/`,
        metadata: {
          order_id: order_id,
        },
      };

      API.post(`/offer/getcredit/`, data, {
        signal: creditController.current.signal,
      })
        .then((res) => {
          const resData = res.data;
          if (resData.status) {
            window.open(resData.paymentOrder, "_self");
          } else {
            toast.error("Something Went Wrong At Credit Purchase Process");
          }
        })
        .catch((error) => {
          if (!error.code || error.code !== "ERR_CANCELED") {
            toast.error("Something Went Wrong At Credit Purchase Process");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getCreditsList("");

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pageStart">
      <Stack
        className="page"
        direction={{ xs: "column", sm: "row" }}
        sx={{ mb: 2 }}
        justifyContent="flex-start"
        alignItems={{ xs: "start", sm: "center" }}
        spacing={1}
      >
        <Typography
          component="h1"
          sx={{
            fontSize: "20px",
            flexGrow: 1,
            textAlign: "center",
            width: "-webkit-fill-available",
          }}
        >
          {t("offers.buy_credits_page.credits")}
        </Typography>
      </Stack>
      <Paper
        sx={{
          backgroundColor: "primaryBg.light",
          width: "max-content",
          margin: "1rem auto",
        }}
        className={styles.custom_container}
        elevation={2}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ borderRadius: "inherit" }}
        >
          <Table
            aria-label="custom pagination table"
            className={"custom-table br-0"}
            sx={{ backgroundColor: "primaryBg.light" }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    sx={{ textAlign: "center" }}
                    style={{ width: "150px" }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.amount?.toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </TableCell>
                  <TableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.credits?.toLocaleString("nl-NL", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell align="left">
                    <Stack direction="row" spacing={1}>
                      <CustomBtn
                        variant="contained"
                        color="secondary"
                        fullWidth
                        size="large"
                        disableRipple
                        loading={false}
                        onClick={() => {
                          showBuyConfirmationDialog();
                          setCreditBuy(row);
                        }}
                      >
                        {t("offers.buy_credits_page.buy_now")}
                      </CustomBtn>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}

              {showNoData && (
                <TableRow>
                  <TableCell colSpan={totalColumns}>
                    <Typography
                      sx={{ width: "100%", textAlign: "center", color: "grey" }}
                      variant="body2"
                    >
                      No Data Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DialogBox
        open={showBuyDialog}
        handleOpen={showBuyConfirmationDialog}
        handleClose={closeBuyConfirmationDialog}
        title={t("offers.buy_credits_page.drop_title")}
        component={
          <div>
            <Alert severity="info">
              {t("offers.buy_credits_page.drop_description")}
            </Alert>
            <div className="boxActions" style={{ justifyContent: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                className="mainButton"
                sx={{ marginRight: "1rem" }}
                onClick={doPayment}
              >
                {t("offers.buy_credits_page.buy_now")}
              </Button>
              <Button
                variant="outlined"
                className="cancelButton"
                onClick={closeBuyConfirmationDialog}
              >
                {t("offers.buy_credits_page.cancel")}
              </Button>
            </div>
          </div>
        }
        maxWidth={"sm"}
        height={"max-content !important"}
      />
    </div>
  );
}

export default BuyCredits;
