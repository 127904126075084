import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Typography,
  Stack,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TablePagination,
  Box,
  TableSortLabel,
  Grid,
  Collapse,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { visuallyHidden } from "@mui/utils";
import PaginationActions from "../../components/table/PaginationActions";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import debounce from "lodash.debounce";
import styles from "../../css/common.module.css";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import useDialogHooks from "../../components/Hooks/useDialogHooks";
import PurchaseOfferDialogBox from "./modals/purchaseOffer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShowContactDetailsDialogBox from "./modals/showContactDetails";
import ShowOfferDetailsDialogBox from "./modals/showOfferDetails";

// ** Credits options
const credits = [
  { name: "10", value: 10 },
  { name: "15", value: 15 },
  { name: "20", value: 20 },
  { name: "25", value: 25 },
  { name: "30", value: 30 },
  { name: "35", value: 35 },
  { name: "40", value: 40 },
  { name: "50", value: 50 },
];

export default function ProfessionalList() {
  const { t } = useTranslation();

  // ** Custom hooks for Dialog box
  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();

  // ** API cancelation Hooks
  const abortController = useRef(new AbortController());

  // ** State Managements
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [search, setSearch] = useState("");
  const [showFilter, SetShowFilter] = useState(true);
  const [maximumDistance, setMaximumDistance] = useState("all");
  const [applicationDate, setApplicationDate] = useState("all");
  const [creditSelected, setCreditSelected] = useState([]);
  const [userId, setUserId] = useState();
  const [offer, setOffer] = useState(0);
  const [location, setlocation] = useState({ latitude: null, longitude: null });

  // ** React hook forms
  const { control, watch } = useForm({
    defaultValues: [],
    mode: "onBlur",
    // resolver: yupResolver(validationSchema),
  });

  // ** Table headers
  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.companyProfile.list_tbl.headers.date"),
      sortable: true,
      width: "200px",
    },
    {
      id: "credits",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.companyProfile.list_tbl.headers.credits"),
      sortable: false,
      width: "100px",
    },
    {
      id: "offers",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.companyProfile.list_tbl.headers.offers"),
      sortable: false,
      width: "100px",
    },
    {
      id: "place",
      numeric: false,
      disablePadding: true,
      label: t("offer.professional.companyProfile.list_tbl.headers.place"),
      sortable: false,
      width: "200px",
    },
    {
      id: "application",
      numeric: false,
      disablePadding: true,
      label: t(
        "offer.professional.companyProfile.list_tbl.headers.application"
      ),
      sortable: false,
      width: "100px",
    },
    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Uitbrengen",
      sortable: false,
      width: "170px",
    },
  ];

  // ** Profession list
  const disciplineList = [
    {
      name: "Boekhouder / Accountant",
      value: "accountant",
    },
    {
      name: "Website / Apps Bouwer",
      value: "developer",
    },
    {
      name: "Grafisch Ontwerper",
      value: "graphicDesigner",
    },
    { name: "Data Analyst", value: "dataAnalist" },
    {
      name: "Coach",
      value: "coach",
    },
    { name: "Advocaat / Jurist", value: "advocate" },
  ];

  // ** Pagination handel function
  const showNoData = !isLoading && rows?.length === 0 ? true : false;
  const skipRows = page * rowsPerPage;
  const totalColumns = headCells.length + 1;

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const debounceSearchInput = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  const discipline = watch("discipline");

  const handleMaximumDistanceButtonClick = (distance) => {
    setMaximumDistance(distance);
  };

  const handleApplicationDateButtonClick = (applicationDate) => {
    setApplicationDate(applicationDate);
  };

  const handleCredis = (credit) => {
    const index = creditSelected.indexOf(credit);

    if (index === -1) {
      setCreditSelected([...creditSelected, credit]);
    } else {
      setCreditSelected(creditSelected.filter((c) => c !== credit));
    }
  };

  // ** API services for Professional list
  const getProfessionalOfferList = () => {
    console.log("getProfessionalOfferList.........................");
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);
    // const { startDate, endDate, customerId, status } = getValues();

    const query = {
      credits: creditSelected.length > 0 ? creditSelected : null,
      status: "approved", // options => 'pending', 'approved', 'rejected' ( this key is optional )
      applicationDate: applicationDate, // options => 'all', 'week', 'today', 'today_and_yesterday', 'yesterday' ( this key is optional )
      distance: maximumDistance, // options => 'all', '10km', '25km', '50km', '75km', '100km' ( this key is optional // this is pending)
      location: location,
      offerType: discipline ? discipline.value : null,
      search: search ? search : null,
      userId: userId,
      usersNotification: true,
      pagination: true,
      page: page ? Number(page) : 0,
      limitPerPage: rowsPerPage,
      sortBy: orderBy,
      descending: order === "asc" ? false : true,
    };

    API.get("offer/list", {
      params: query,
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res;
        if (resData.status) {
          setRows(resData.data.data);
          setTotalRows(resData.data.totalDocsCount || 0);
          setPage(
            resData.data.currentPage > resData.data.totalPage
              ? 0
              : resData.data.currentPage - 1
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // ** Mount components
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setlocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }

    let user = localStorage.getItem("dashbook_user")
      ? JSON.parse(localStorage.getItem("dashbook_user"))
      : {};

    setUserId(user.id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProfessionalOfferList();
    return () => {
      abortController.current.abort();
    };

    // eslint-disable-next-line
  }, [
    search,
    discipline,
    applicationDate,
    userId,
    page,
    order,
    orderBy,
    rowsPerPage,
    creditSelected,
    location,
    maximumDistance,
  ]);

  return (
    <>
      <Stack mb={4}>
        <Grid container rowGap={2}>
          <Collapse sx={{ width: "100%", marginTop: 1 }} in={showFilter}>
            <Grid container item xs={12} spacing={12}>
              <Grid item xs={12} sm={4}>
                <AutocompleteInput
                  name="discipline"
                  type="text"
                  label={t("offer.professional.professionalList.discipline")}
                  control={control}
                  options={disciplineList}
                  getOptionLabel={(option) => option.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid sx={{ marginBottom: "10px" }}>
                  <label>
                    {t("offer.professional.professionalList.applicationDate")}
                  </label>
                  <div>
                    <Button
                      color="secondary"
                      variant={
                        applicationDate === "all" ? "contained" : "outlined"
                      }
                      onClick={() => handleApplicationDateButtonClick("all")}
                      sx={{ margin: "10px" }}
                    >
                      {t("offer.professional.professionalList.all")}
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        applicationDate === "week" ? "contained" : "outlined"
                      }
                      onClick={() => handleApplicationDateButtonClick("week")}
                      sx={{ margin: "10px" }}
                    >
                      {t("offer.professional.professionalList.thisWeek")}
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        applicationDate === "today_and_yesterday"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() =>
                        handleApplicationDateButtonClick("today_and_yesterday")
                      }
                      sx={{ margin: "10px" }}
                    >
                      {t(
                        "offer.professional.professionalList.todayAndYesterday"
                      )}
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        applicationDate === "today" ? "contained" : "outlined"
                      }
                      onClick={() => handleApplicationDateButtonClick("today")}
                      sx={{ margin: "10px" }}
                    >
                      {t("offer.professional.professionalList.today")}
                    </Button>
                  </div>
                </Grid>
                <Grid sx={{ marginBottom: "10px" }}>
                  <label>
                    {t("offer.professional.professionalList.maximumDistance")}
                  </label>
                  <div style={{ marginLeft: "2px" }}>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "all" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("all")}
                      sx={{ margin: "10px" }}
                    >
                      {t("offer.professional.professionalList.all")}
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "100km" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("100km")}
                      sx={{ margin: "10px" }}
                    >
                      100 km
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "75km" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("75km")}
                      sx={{ margin: "10px" }}
                    >
                      75 km
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "50km" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("50km")}
                      sx={{ margin: "10px" }}
                    >
                      50 km
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "25km" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("25km")}
                      sx={{ margin: "10px" }}
                    >
                      25 km
                    </Button>
                    <Button
                      color="secondary"
                      variant={
                        maximumDistance === "10km" ? "contained" : "outlined"
                      }
                      onClick={() => handleMaximumDistanceButtonClick("10km")}
                      sx={{ margin: "10px" }}
                    >
                      10 km
                    </Button>
                  </div>
                </Grid>
                <Grid>
                  <label>
                    {t("offer.professional.professionalList.rateCredits")}{" "}
                  </label>
                  <div>
                    <FormGroup aria-label="position" row>
                      {credits.map((rowData) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              onClick={() => handleCredis(rowData.value)}
                            />
                          }
                          label={rowData.name}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>

          <Grid container item xs={12} sm={9} spacing={3}>
            <Grid item xs={12} sm={9}>
              <TextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ bgcolor: "white" }}
                placeholder={t("search")}
                onChange={debounceSearchInput}
              />
              <Button
                color="info"
                onClick={() => SetShowFilter((prev) => !prev)}
              >
                {showFilter
                  ? `${t("offers.hide_filter")}`
                  : `${t("offers.show_filter")}`}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </Grid>
      </Stack>
      <Paper
        sx={{ backgroundColor: "primaryBg.light" }}
        className={styles.custom_container}
        elevation={2}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ borderRadius: "inherit" }}
        >
          <Table
            aria-label="custom pagination table"
            className={"custom-table br-0"}
            sx={{ backgroundColor: "primaryBg.light", minWidth: 600 }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "80px",
                    minWidth: "80px",
                    textAlign: "center",
                  }}
                >
                  {t("offers.list_tbl.headers.no")}
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ minWidth: headCell.width || "100px" }}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell style={{ textAlign: "center" }}>
                    {skipRows + (index + 1)}
                  </TableCell>
                  <TableCell scope="row">
                    {dayjs(row?.approvedAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell scope="row">{row?.credit}</TableCell>
                  <TableCell scope="row">{row?.appliedUsers.length}</TableCell>
                  <TableCell scope="row">{row?.company?.address}</TableCell>
                  <TableCell
                    scope="row"
                    // sx={{ display: "flex", alignItems: "center" }}
                  >
                    {row?.explanation}&nbsp;&nbsp;
                    <Button
                      variant="text"
                      sx={{ textTransform: "none" }}
                      onClick={() => {
                        setOffer(row);
                        openDialog("showOfferDetails");
                      }}
                    >
                      ...{t("offer.professional.professionalList.viewMore")}
                    </Button>
                  </TableCell>
                  {!row?.appliedUsers.includes(userId) && (
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          // height: "100%",
                          backgroundColor: "#00c078", // Set the background color to green
                          color: "#ffffff", // Set the text color to ensure readability
                          textTransform: "none",
                          width: "100px",
                        }}
                        onClick={() => {
                          setOffer(row);
                          openDialog("purchaseOffer");
                        }}
                      >
                        {t("offer.professional.companyProfile.offer")}
                      </Button>
                    </TableCell>
                  )}
                  {row?.appliedUsers.includes(userId) && (
                    <TableCell align="left">
                      <InfoOutlinedIcon
                        style={{ textAlign: "center" }}
                        onClick={() => {
                          setOffer(row);
                          openDialog("showContactDetails");
                        }}
                      />
                      &nbsp;
                      <Button
                        disabled
                        variant="outlined"
                        disableElevation
                        style={{
                          // height: "100%",
                          // backgroundColor: "#00c078", // Set the background color to green
                          // color: "#ffffff", // Set the text color to ensure readability
                          textTransform: "none",
                          width: "100px",
                        }}
                      >
                        {t("offer.professional.professionalList.bringOut")}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {showNoData && (
                <TableRow>
                  <TableCell colSpan={totalColumns}>
                    <Typography
                      sx={{ width: "100%", textAlign: "center", color: "grey" }}
                      variant="body2"
                    >
                      {t("noDataAvailable")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={styles.custom_tbl_pagination}>
          <TablePagination
            as="div"
            rowsPerPageOptions={[
              1,
              5,
              10,
              25,
              50,
              { label: "All", value: 99999999 },
            ]}
            labelRowsPerPage={t("rows_per_page")}
            colSpan={totalColumns}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            ActionsComponent={PaginationActions}
          />
        </Box>
      </Paper>
      {isDialogOpen("purchaseOffer") && (
        <PurchaseOfferDialogBox
          isOpen={() => isDialogOpen("purchaseOffer")}
          onClose={() => closeDialog("purchaseOffer")}
          control={control}
          offer={offer}
          getProfessionalOfferList={getProfessionalOfferList}
        />
      )}
      {isDialogOpen("showContactDetails") && (
        <ShowContactDetailsDialogBox
          isOpen={() => isDialogOpen("showContactDetails")}
          onClose={() => closeDialog("showContactDetails")}
          control={control}
          offer={offer}
        />
      )}
      {isDialogOpen("showOfferDetails") && (
        <ShowOfferDetailsDialogBox
          isOpen={() => isDialogOpen("showOfferDetails")}
          onClose={() => closeDialog("showOfferDetails")}
          control={control}
          offer={offer}
        />
      )}
    </>
  );
}
