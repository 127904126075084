import React, { useMemo } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import coachImage from "../assets/Options/User.png";
import accountantImage from "../assets/Options/Calculator.png";
import websiteImage from "../assets/Options/Browser.png";
import graphicDesignerImage from "../assets/Options/BezierCurve.png";
import dataAnalistImage from "../assets/Options/ChartBar.png";
import advocateImage from "../assets/Options/Scales.png";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockResetIcon from "@mui/icons-material/LockReset";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../hooks/auth";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DialogBox from "../components/DialogBox";
import AddAccountant from "../pages/accountant/AddAccountant";
import AddDeveloper from "../pages/website/AddDeveloper";
import AddGraphicDesigner from "../pages/graphicDesigner/AddGraphicDesigner";
import AddAnalyst from "../pages/dataAnalyst/AddAnalyst";
import AddCoach from "../pages/coach/AddCoach";
import AddAdvocate from "../pages/advocate/AddAdvocate";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: trigger
      ? { boxShadow: "0 7px 15px 0 rgba(100, 100, 111, 0.2)" }
      : {},
  });
}

export default function Header({
  drawerWidth,
  handleDrawerToggle,
  handleDrawerWidthToggle,
  showFullDrawer,
  isNavbarOpen,
  setIsNavbarOpen,
  setTitle,
  setComponent,
  setOpen,
  dialogOpen,
  title,
  component,
}) {
  const isDarkTheme = useTheme().palette.mode === "dark";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const auth = useAuth();
  const user = auth?.user;

  const userName = useMemo(() => user?.name || "", [user]);
  const userProfilePic = useMemo(() => user?.profilePic || "", [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const open = Boolean(anchorEl);

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
  };

  // Scroll To Offer Div
  // const scrollToOffers = () => {
  //   const offersElement = document.getElementById("offers");
  //   if (offersElement) {
  //     offersElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const manageDialogComponent = (type) => {
    setIsNavbarOpen(false);
    let title;
    switch (type) {
      case "Website":
        title = t("offers.offer_create.website.page_title");
        break;
      case "Advocate":
        title = t("offers.offer_create.advocate.page_title");
        break;
      case "Coach":
        title = t("offers.offer_create.coach.page_title");
        break;
      case "DataAnalyst":
        title = t("offers.offer_create.data_analyst.page_title");
        break;
      case "GraphicDesigner":
        title = t("offers.offer_create.graphic_designer.page_title");
        break;
      case "Accountant":
        title = t("offers.offer_create.accountant.page_title");
        break;
      default:
        break;
    }

    setTitle(title);
    setComponent(`${type}`);
    setOpen(true);
  };
  // Open Popup Box
  const handleOpen = () => {
    setOpen(true);
  };

  // Close Popup Box
  const handleClose = () => {
    setOpen(false);
    // Remove All Component State
    setComponent(null);

    // User Offer Edit
    // setEditOfferId(null)
  };

  return (
    <ElevationScroll>
      <div className="sticky">
        <div className="desktop">
          <Toolbar style={{ justifyContent: "space-between", width: "100%" }}>
            <div className="navbarLinks">
              <Link className="navLink" to={"/"}>
                <div
                  style={{
                    width: "max-content",
                    display: "flex",
                    color: "#D0AF88",
                  }}
                >
                  <img
                    style={{ margin: "auto", marginRight: "5px" }}
                    src={"/logo.svg"}
                    alt="Dashbook Logo"
                    width={"100px"}
                    height={"16px"}
                  />
                  {t("offers.header.offer").toUpperCase()}
                </div>
              </Link>
              <div
                style={{
                  display: "flex",
                  width: "-webkit-fill-available",
                  justifyContent: "center",
                }}
              >
                <Link
                  className={`navLink ${
                    isNavbarOpen === false && pathname === "/" ? "active" : ""
                  }`}
                  to={"/"}
                >
                  {t("offers.header.home")}
                </Link>
                <Link
                  className={`navLink ${isNavbarOpen ? "active" : ""}`}
                  onClick={() => {
                    setIsNavbarOpen(!isNavbarOpen);
                  }}
                >
                  {t("offers.header.all_categories")}
                  <img
                    src={isNavbarOpen ? "./CloseIcon.png" : "./OpenIcon.png"}
                    height={"15px"}
                    alt=""
                    style={{ marginLeft: "5px" }}
                  />
                </Link>
                <Link
                  className={`navLink ${
                    isNavbarOpen === false && pathname === "/contact"
                      ? "active"
                      : ""
                  }`}
                  to={"/contact"}
                >
                  {t("offers.footer.contact_title")}
                </Link>
                <Link
                  className={`navLink ${
                    // isNavbarOpen === false && pathname === "/buycredits"
                    isNavbarOpen === false && pathname === "/questions"
                      ? "active"
                      : ""
                  }`}
                  to={"/questions?number=12"}
                  // to={"/buycredits"}
                >
                  {t("offers.footer.buy_credits")}
                </Link>
                {userName && (
                  <Link
                    className={`navLink ${
                      // isNavbarOpen === false && pathname === "/buycredits"
                      isNavbarOpen === false &&
                      pathname.includes("professionalList")
                        ? "active"
                        : ""
                    }`}
                    to={"/professionalList/featured"}
                    // to={"/buycredits"}
                  >
                    {t("offer.title")}
                  </Link>
                )}
              </div>
            </div>
            <div style={{ display: `${userName ? "none" : "block"}` }}>
              <Button
                variant="outlined"
                color="primary"
                className="mainButton"
                onClick={() => navigate("/login")}
              >
                {t("offers.header.login")}
              </Button>
            </div>
            <Tooltip
              style={{ display: `${userName ? "block" : "none"}` }}
              title="Account settings"
            >
              <IconButton
                onClick={openProfileMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  alt={userName}
                  variant="dense"
                  src={userProfilePic}
                  sx={{
                    bgcolor: isDarkTheme ? "#ecdac6" : "#c69053",
                    width: 32,
                    height: 32,
                  }}
                >
                  {userName?.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              style={{ display: `${userName ? "block" : "none"}` }}
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={closeProfileMenu}
              onClick={closeProfileMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                {t("offers.header.profile")}
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <LockResetIcon />
                </ListItemIcon>
                {t("offers.header.change_password")}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("offers.header.logout")}
              </MenuItem>
            </Menu>
          </Toolbar>
          <div
            className="dropDown"
            id="dropDownHeader"
            style={{
              display: isNavbarOpen ? "block" : "none",
              padding: "1rem 2rem",
            }}
          >
            <Grid container>
              <Grid item xs={0} md={4} sm={0}>
                <div className="dropImageBox">
                  <div className="dropImage"></div>
                </div>
              </Grid>
              <Grid item xs={12} md={8} sm={12}>
                <Grid
                  container
                  spacing={2}
                  p={2}
                  justifyContent="center"
                  sx={{
                    height: "max-content !important",
                    width: "-webkit-fill-available !important",
                    alignSelf: "center",
                    position: "relative",
                    margin: "auto",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("Accountant")}
                    >
                      <img
                        src={accountantImage}
                        alt=""
                        className="buttonIcon"
                      />
                      {t("offers.header.accountant")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("Website")}
                    >
                      <img src={websiteImage} alt="" className="buttonIcon" />
                      {t("offers.header.website_apps_builder")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("GraphicDesigner")}
                    >
                      <img
                        src={graphicDesignerImage}
                        alt=""
                        className="buttonIcon"
                      />
                      {t("offers.header.graphic_designer")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("DataAnalyst")}
                    >
                      <img
                        src={dataAnalistImage}
                        alt=""
                        className="buttonIcon"
                      />
                      {t("offers.header.data_analyst")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("Coach")}
                    >
                      <img src={coachImage} alt="" className="buttonIcon" />
                      {t("offers.header.coach")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      className="dropButton"
                      onClick={() => manageDialogComponent("Advocate")}
                    >
                      <img src={advocateImage} alt="" className="buttonIcon" />
                      {t("offers.header.advocate")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            {component && component === "Accountant" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAccountant handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Website" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddDeveloper handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "GraphicDesigner" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddGraphicDesigner handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "DataAnalyst" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAnalyst handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Coach" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddCoach handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Advocate" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAdvocate handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mobile" style={{ padding: "1rem" }}>
          <div
            className="navbarLinks"
            style={{ justifyContent: "space-between" }}
          >
            <Link className="navLink" to={"/"}>
              <div
                style={{
                  width: "max-content",
                  display: "flex",
                  color: "#D0AF88",
                }}
              >
                <img
                  style={{ margin: "auto", marginRight: "5px" }}
                  src={"/logo.svg"}
                  alt="Dashbook Logo"
                  width={"100px"}
                  height={"16px"}
                />
                {t("offers.header.offer").toUpperCase()}
              </div>
            </Link>
            {mobileMenu ? (
              <button
                onClick={() => setMobileMenu(false)}
                style={{
                  border: "none",
                  margin: "0px",
                  padding: "0px",
                  backgroundColor: "transparent",
                }}
              >
                <CloseOutlinedIcon sx={{ margin: "5px" }} />
              </button>
            ) : (
              <button
                onClick={() => setMobileMenu(true)}
                style={{
                  border: "none",
                  margin: "0px",
                  padding: "0px",
                  backgroundColor: "transparent",
                }}
              >
                <MenuTwoToneIcon sx={{ margin: "5px" }} />
              </button>
            )}
          </div>
          <div
            style={{
              display: mobileMenu ? "flex" : "none",
              flexDirection: "column",
              width: "-webkit-fill-available",
              justifyContent: "center",
            }}
          >
            {" "}
            <div style={{ textAlign: "right" }}>
              <Tooltip
                style={{ display: `${userName ? "inline-block" : "none"}` }}
                title="Account settings"
              >
                <IconButton
                  onClick={openProfileMenu}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    alt={userName}
                    variant="dense"
                    src={userProfilePic}
                    sx={{
                      bgcolor: isDarkTheme ? "#ecdac6" : "#c69053",
                      width: 32,
                      height: 32,
                    }}
                  >
                    {userName?.charAt(0)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                style={{ display: `${userName ? "block" : "none"}` }}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={closeProfileMenu}
                onClick={closeProfileMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  {t("offers.header.profile")}
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <LockResetIcon />
                  </ListItemIcon>
                  {t("offers.header.change_password")}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("offers.header.logout")}
                </MenuItem>
              </Menu>
            </div>
            <div
              style={{
                display: `${userName ? "none" : "block"}`,
                padding: "1rem",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                className="mainButton"
                onClick={() => navigate("/login")}
              >
                {t("offers.header.login")}
              </Button>
            </div>
            <Link
              className={`navLink ${
                isNavbarOpen === false && pathname === "/" ? "active" : ""
              }`}
              to={"/"}
            >
              {t("offers.header.home")}
            </Link>
            <Link
              className={`navLink ${isNavbarOpen ? "active" : ""}`}
              onClick={() => {
                setIsNavbarOpen(!isNavbarOpen);
              }}
            >
              {t("offers.header.all_categories")}
              <img
                src={isNavbarOpen ? "./CloseIcon.png" : "./OpenIcon.png"}
                height={"15px"}
                alt=""
                style={{ marginLeft: "5px" }}
              />
            </Link>
            <div
              className="dropDown"
              id="dropDownHeader"
              style={{
                display: isNavbarOpen ? "block" : "none",
              }}
            >
              <Grid container>
                <Grid item xs={12} md={8} sm={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    sx={{
                      height: "max-content !important",
                      width: "-webkit-fill-available !important",
                      alignSelf: "center",
                      position: "relative",
                      margin: "auto",
                    }}
                  >
                    {/* <Grid
                      item
                      xs={12}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 1,
                        paddingTop: "0px !important",
                      }}
                    >
                      <IconButton
                        size="small"
                        aria-label="edit"
                        onClick={() => setIsNavbarOpen(false)}
                      >
                        <DisabledByDefaultIcon
                          className="edit_icon"
                          fontSize="medium"
                        />
                      </IconButton>
                    </Grid> */}

                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("Accountant")}
                      >
                        <img
                          src={accountantImage}
                          alt=""
                          className="buttonIcon"
                        />
                        {t("offers.header.accountant")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("Website")}
                      >
                        <img src={websiteImage} alt="" className="buttonIcon" />
                        {t("offers.header.website_apps_builder")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("GraphicDesigner")}
                      >
                        <img
                          src={graphicDesignerImage}
                          alt=""
                          className="buttonIcon"
                        />
                        {t("offers.header.graphic_designer")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("DataAnalyst")}
                      >
                        <img
                          src={dataAnalistImage}
                          alt=""
                          className="buttonIcon"
                        />
                        {t("offers.header.data_analyst")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("Coach")}
                      >
                        <img src={coachImage} alt="" className="buttonIcon" />
                        {t("offers.header.coach")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: "0px" }}>
                      <Button
                        variant="contained"
                        className="dropButton"
                        onClick={() => manageDialogComponent("Advocate")}
                      >
                        <img
                          src={advocateImage}
                          alt=""
                          className="buttonIcon"
                        />
                        {t("offers.header.advocate")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Link
              className={`navLink ${
                isNavbarOpen === false && pathname === "/contact"
                  ? "active"
                  : ""
              }`}
              to={"/contact"}
            >
              {t("offers.footer.contact_title")}
            </Link>
            <Link
              className={`navLink ${
                isNavbarOpen === false && pathname === "/buycredits"
                  ? "active"
                  : ""
              }`}
              to={"/buycredits"}
            >
              {t("offers.footer.buy_credits")}
            </Link>
          </div>
          <div>
            {component && component === "Accountant" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAccountant handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Website" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddDeveloper handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "GraphicDesigner" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddGraphicDesigner handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "DataAnalyst" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAnalyst handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Coach" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddCoach handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
            {component && component === "Advocate" ? (
              <DialogBox
                open={dialogOpen}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={title}
                component={<AddAdvocate handleClose={handleClose} />}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </ElevationScroll>
  );
}
