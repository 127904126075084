import React from "react";
import { useTranslation } from "react-i18next";

function AdvocateDetails({ offer }) {
  const { t } = useTranslation();
  const workType = [
    {
      name: t("offers.offer_create.advocate.checking_contract"),
      value: "checkingContract",
    },
    {
      name: t(
        "offers.offer_create.advocate.checking_general_terms_and_conditions"
      ),
      value: "checkingTermsConditions",
    },
    {
      name: t("offers.offer_create.advocate.tax_matters"),
      value: "taxMatters",
    },
    {
      name: t("offers.offer_create.advocate.business_conflict"),
      value: "businessConflict",
    },
    {
      name: t("offers.offer_create.advocate.family_matters"),
      value: "familyMatters",
    },
    {
      name: t("offers.offer_create.advocate.real_estate"),
      value: "realEstate",
    },
    {
      name: t("offers.offer_create.advocate.personal_injury"),
      value: "personalInjury",
    },
    { name: t("offers.offer_create.advocate.other"), value: "other" },
  ];

  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Deadlines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];
  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.advocate.what_work_lable")}</p>
      <ul className="answers">
        {offer.workType.map((item)=>(
            <li>{workType.find((work)=>work.value === item).name}</li>
        ))}
      </ul>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.desired_location")}</p>
      <p className="detailsValue">{workMode.find((item) => item.value === offer.workMode).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item)=>item.value === offer.deadline).name}</p>
    </div>
  );
}

export default AdvocateDetails;
