import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import CheckBox from "../../components/form/CheckBox";
import { useSelector } from "react-redux";
import API from "../../config/axios";
import toast from "react-hot-toast";
import { highlightField } from "../../utils/Commons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CountriesMenu from "../../components/CountriesMenu";
import { useTranslation } from "react-i18next";
import CareerCoach from "./options/CareerCoach";
import BudgetCoach from "./options/BudgetCoach";
import LifeCoach from "./options/LifeCoach";
import EmotionalCoach from "./options/EmotionalCoach";
import BusinessCoach from "./options/BusinessCoach";
import LocationPicker from "../../components/form/LocationPicker";
import CustomDialogBox from "../../components/CustomDialogBox";
import { useNavigate } from "react-router-dom";

// Emotional Coach Validation Schema
const emotionalCoachSchema = yup.object({
  contactType: yup.object().required(),
  subCoachType: yup.object().required(),
  deadline: yup.object().required(),
  helpType: yup.object().required(),
  gender: yup.object().required(),
  age: yup.number().required().min(1).max(125),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
});

// Budget Coach Validation Schema
const budgetCoachSchema = yup.object({
  deadline: yup.object().required(),
  contactType: yup.object().required(),
  gender: yup.object().required(),
  age: yup.number().required().min(1).max(125),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
});

// Life Coach Validation Schema
const lifeCoachSchema = yup.object({
  workSituation: yup.object().required(),
  gender: yup.object().required(),
  contactType: yup.object().required(),
  subCoachType: yup.object().required(),
  deadline: yup.object().required(),
  age: yup.number().required().min(1).max(125),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
});

// Business Coach Validation Schema
const businessCoachSchema = yup.object({
  helpType: yup.array().of(yup.object()).min(1),
  contactType: yup.object().required(),
  deadline: yup.object().required(),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
});

// Career Coach Validation Schema
const careerCoachSchema = yup.object({
  workSituation: yup.object().required(),
  gender: yup.object().required(),
  contactType: yup.object().required(),
  deadline: yup.object().required(),
  age: yup.number().required().min(1).max(125),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
  education: yup.string().required(),
});

// Form Validation Schema
const mainSchema = yup.object().shape({
  companyOwnerName: yup.string().required("Owner Name is required Field"),
  companyAddress: yup.string().required("Company Address is required Field"),
  companyEmail: yup
    .string()
    .email("Please Enter Valid Email Id")
    .required("Company Email is required Field"),
  companyPostalCode: yup
    .number()
    .required("Company PostalCode is required Field"),
  companyPhoneNo: yup
    .string()
    .length(10, "Phone number must be exactly 10 digits")
    .required(),
  companyCountryCode: yup.string().nullable(),
  companyName: yup.string().nullable(),
  companyWebsite: yup.string().nullable(),
  companyLocation: yup.boolean(),
  companyEmailPreference: yup.boolean(),
  companyQuoteRequest: yup.boolean().nullable(),
  companyCountryId: yup.string().nullable(),

  termsAndCondition: yup.boolean().required().oneOf([true]),
  coachType: yup
    .object()
    .shape({
      value: yup.string().required().notOneOf([null, ""]),
    })
    .required(),
});

function AddCoach({ handleClose }) {
  const { t } = useTranslation();
  const countries = useSelector((state) => state.common.countries);
  const navigate = useNavigate();

  const [inputErrors, setInputErrors] = useState({});
  const coachOfferController = useRef(new AbortController());

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [formType, setFormType] = useState();
  const [warningBox, setWarningBox] = useState(false);

  const saveAction = () => {
    setWarningBox(false);
    handleClose(); // close popup
  };
  // Work Type Options
  const coachType = [
    { name: t("offers.offer_create.coach.career_coach"), value: "careerCoach" },
    {
      name: t("offers.offer_create.coach.business_coach"),
      value: "businessCoach",
    },
    { name: t("offers.offer_create.coach.life_coach"), value: "lifeCoach" },
    {
      name: t("offers.offer_create.coach.emotional_coach"),
      value: "emotionalCoach",
    },
    { name: t("offers.offer_create.coach.budget_coach"), value: "budgetCoach" },
  ];

  // Default Values For Create Accountant Form
  const defaultValues = {
    companyCountryId: null,

    coachType: null,

    companyName: null,
    companyOwnerName: null,
    termsAndCondition: null,
    companyQuoteRequest: false,
    companyPhoneNo: null,
    companyCountryCode: null,
    companyEmail: null,
    companyWebsite: null,
    companyLocation: false,
    companyEmailPreference: false,
    companyAddress: null,
    companyPostalCode: null,
  };

  const concatSchema = (coachType) => {
    switch (coachType) {
      case "emotionalCoach":
        setFormType("emotionalCoach");
        break;
      case "budgetCoach":
        setFormType("budgetCoach");
        break;
      case "lifeCoach":
        setFormType("lifeCoach");
        break;
      case "businessCoach":
        setFormType("businessCoach");
        break;
      case "careerCoach":
        setFormType("careerCoach");
        break;
      default:
    }
  };

  // Set Form Default Values
  const { handleSubmit, control, setValue, setError } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const onError = (errors) => {
    console.log(errors);
    let newErrorObj = {};
    Object.keys(errors).forEach((item) => {
      if (item === "companyAddressCoordinates") {
        const key = "companyAddress";
        newErrorObj[key] = errors[key]["message"];
      } else {
        const key = item;
        newErrorObj[key] = errors[key]["message"];
      }
    });
    setInputErrors(newErrorObj);
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error);
      console.warn("Field cannot be highlighted");
    }
  };

  // Form Data Validation According to Coach Type
  const customValidation = (formData) => {
    let schema = null;
    switch (formData.coachType.value) {
      case "emotionalCoach":
        schema = emotionalCoachSchema;
        break;
      case "budgetCoach":
        schema = budgetCoachSchema;
        break;
      case "lifeCoach":
        schema = lifeCoachSchema;
        break;
      case "businessCoach":
        schema = businessCoachSchema;
        break;
      case "careerCoach":
        schema = careerCoachSchema;
        break;
      default:
        schema = {};
        break;
      // Handle default case or do nothing
    }

    if (schema) {
      try {
        mainSchema.concat(schema).validateSync(formData, { abortEarly: false });
        return true;
      } catch (error) {
        let newErrorObj = {};

        // eslint-disable-next-line array-callback-return
        error.inner?.map((inner, index) => {
          const { path, errors } = inner;
          setError(path, { message: errors[0] });

          // Object.keys(errors).forEach((item) => {
          if (path === "companyAddressCoordinates") {
            const key = "companyAddress";
            newErrorObj[key] = errors[0];
          } else {
            const key = path;
            newErrorObj[key] = errors[0];
          }
          // });
          setInputErrors(newErrorObj);
        });
        return false;
      }
    }
  };

  // Create New Coach Via Api
  const createCoachOffer = async (formData) => {
    const formDataUpdated = {
      ...formData,
      age: Number(formData.age),
      offerType: formType,
    };
    const validate = customValidation(formDataUpdated);
    if (validate) {
      coachOfferController.current.abort();
      coachOfferController.current = new AbortController();

      API.post(`/offer/`, formDataUpdated, {
        signal: coachOfferController.current.signal,
      })
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            setWarningBox(true);
          } else {
            toast.error("Something Went Wrong At Create Coach Offer");
          }
        })
        .catch((error) => {
          if (!error.code || error.code !== "ERR_CANCELED") {
            toast.error("Something Went Wrong At Create Coach Offer");
          }
        })
        .finally(() => {
          // setIsLoadingPeriods(false);
        });
    }
  };

  // On Click Event For Phone And Country Select
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Set Country Code And Country Id
  useEffect(() => {
    if (countryCode && countryCode !== "") {
      setValue("companyCountryCode", countryCode);
      const country = countries.find((country) => country.code === countryCode);
      if (country && country.id) setValue("companyCountryId", country.id);
    }
    // eslint-disable-next-line
  }, [countryCode]);

  useEffect(() => {
    setInputErrors({});
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-1">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(createCoachOffer, onError)}
      >
        <CustomDialogBox
          isOpen={warningBox}
          maxWidth={"xs"}
          title={t("offers.offer_create.success_box.title")}
          onClose={() => {
            setWarningBox(false);
            handleClose(); // close popup
          }}
          children={
            <div>
              <p>{t("offers.offer_create.success_box.tnx_for_req")}</p>
              <p>{t("offers.offer_create.success_box.check_mail")}</p>
              <p>{t("offers.offer_create.success_box.we_sent_mail")}</p>
              <p>{t("offers.offer_create.success_box.ask_rcv_email")}</p>
              <p>{t("offers.offer_create.success_box.check_spam")}</p>
              <p>{t("offers.offer_create.success_box.add_us_allow_list")}</p>
              <p>
                {t("offers.offer_create.success_box.contact_us_via")}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                    color: "#D0AF88",
                  }}
                  onClick={() => {
                    setWarningBox(false);
                    handleClose(); // close popup
                    navigate("/questions?number=12");
                  }}
                >
                  {t("offers.offer_create.success_box.contact_us_via_link")}
                </button>
              </p>
            </div>
          }
          actions={
            <Button
              type="submit"
              variant="contained"
              className="cancelButton"
              onClick={saveAction}
            >
              {t("offers.offer_create.success_box.close")}
            </Button>
          }
        />
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="coachType"
                type="text"
                label={""}
                control={control}
                options={coachType}
                getOptionLabel={(option) => option.name}
                onChangeCallback={(_, option, reason) => {
                  concatSchema(option.value);
                }}
                disableClearable={true}
                customError={
                  inputErrors.coachType?.value || inputErrors.coachType || null
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <div>
          {formType && formType === "lifeCoach" ? (
            <LifeCoach t={t} inputErrors={inputErrors} control={control} />
          ) : (
            ""
          )}
          {formType && formType === "careerCoach" ? (
            <CareerCoach t={t} inputErrors={inputErrors} control={control} />
          ) : (
            ""
          )}
          {formType && formType === "businessCoach" ? (
            <BusinessCoach t={t} inputErrors={inputErrors} control={control} />
          ) : (
            ""
          )}
          {formType && formType === "emotionalCoach" ? (
            <EmotionalCoach t={t} inputErrors={inputErrors} control={control} />
          ) : (
            ""
          )}
          {formType && formType === "budgetCoach" ? (
            <BudgetCoach t={t} inputErrors={inputErrors} control={control} />
          ) : (
            ""
          )}
        </div>
        {formType && formType !== "" ? (
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Typography style={{ color: "#d18615" }} fontSize="20px">
                {t(`offers.offer_create.website.contact_details`)}
              </Typography>
              <Typography color="black" fontSize="20px">
                {t(`offers.offer_create.website.contact_details_will_private`)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyOwnerName"
                  labelTheme={true}
                  type="text"
                  label={t("offers.offer_create.website.your_name")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyOwnerName || null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyName"
                  labelTheme={true}
                  type="text"
                  label={t("offers.offer_create.website.comapny_name")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyName || null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Grid item sm={6} xs={12} md={4}>
                  <TextInput
                    name="companyPostalCode"
                    labelTheme={true}
                    type="text"
                    label={t("offers.offer_create.website.post_code")}
                    autoFocus={true}
                    control={control}
                    customError={inputErrors.companyPostalCode || null}
                  />
                </Grid>
                <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                  <CheckBox
                    label={t(
                      "offers.offer_create.website.located_outside_netherland"
                    )}
                    control={control}
                    name="companyLocation"
                    labelTheme={true}
                    color="info"
                    inputProps={{ "aria-label": "controlled" }}
                    customError={inputErrors.companyLocation || null}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyEmail"
                  labelTheme={true}
                  type="email"
                  label={t("offers.offer_create.website.email")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyEmail || null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyWebsite"
                  labelTheme={true}
                  type="text"
                  label={t("offers.offer_create.website.website")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyWebsite || null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Grid item sm={6} xs={12} md={4}>
                  <div>
                    <TextInput
                      name="companyPhoneNo"
                      labelTheme={true}
                      label={t("offers.offer_create.website.mobile_number")}
                      control={control}
                      customError={inputErrors.companyPhoneNo || null}
                      fullWidth
                      onChangeCallback={(inputValue) => {
                        const newValue = inputValue
                          .replace(/^0+/, "")
                          .replace(/[^0-9]/g, "");
                        setValue("companyPhoneNo", newValue);
                      }}
                      InputProps={{
                        startAdornment: (
                          <Box
                            style={{
                              marginRight: "5px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={handleClick}
                          >
                            +{countryCode}
                            <KeyboardArrowDownIcon
                              sx={{ color: "grey", fontSize: 16 }}
                            />
                          </Box>
                        ),
                      }}
                    />
                    <CountriesMenu
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      open={open}
                      setOpen={setOpen}
                      countryCode={countryCode}
                      setCountryCode={setCountryCode}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                  <CheckBox
                    label={t("offers.offer_create.website.email_to_receive")}
                    control={control}
                    name="companyEmailPreference"
                    color="info"
                    inputProps={{ "aria-label": "controlled" }}
                    customError={inputErrors.companyEmailPreference || null}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <LocationPicker
                  control={control}
                  labelTheme={true}
                  placeholder={t("offers.offer_create.website.company_address")}
                  customError={inputErrors.companyAddress || null}
                  setValue={setValue}
                  inputName={"companyAddress"}
                  CoordinatesTagName={"companyAddressCoordinates"}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
              <Grid item sm={6} xs={12} md={4}>
                <CheckBox
                  label={t("offers.offer_create.website.company_quote_request")}
                  control={control}
                  name="companyQuoteRequest"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyQuoteRequest || null}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "0px !important",
                paddingTop: "0px !important",
              }}
            >
              <Box sx={{ width: "max-content" }}>
                <CheckBox
                  label={t("offers.offer_create.website.accept_condition")}
                  control={control}
                  name="termsAndCondition"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.termsAndCondition || null}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: "max-content", marginX: "auto" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "green", color: "white" }}
                >
                  {t("offers.offer_create.request_quotes")}
                  <img
                    alt=""
                    src={"./nextWhite.png"}
                    style={{ marginLeft: "10px" }}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </form>
      {formType && formType !== "" ? (
        <div>
          <div className="flex" style={{ marginBottom: "0.5rem" }}>
            <img
              src="./greenCheck.png"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <Typography
              variant="body1"
              align="center"
              style={{ color: "#0fa958" }}
            >
              {t("offers.offer_create.request_free_quotes")}
            </Typography>
          </div>
          <div className="flex">
            <img
              src="./greenCheck.png"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <Typography
              variant="body1"
              align="center"
              style={{ color: "#0fa958" }}
            >
              Binnen 24 uur ontvang je meerdere offertes
            </Typography>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default AddCoach;
