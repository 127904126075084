import React from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialogBox from "../../../components/CustomDialogBox";
import { v4 } from "uuid";
import API from "../../../config/axios";
import { toast } from "react-hot-toast";
import { useRef } from "react";

const DoPaymentDialogBox = ({ isOpen, onClose, creditBuy, userId }) => {
  console.log("creditBuy", creditBuy);
  const { t } = useTranslation();
  //   const [isLoading, setIsLoading] = useState(false);
  const creditController = useRef(new AbortController());

  const convertAmount = (amountInRupees) => {
    const amountFloat = parseFloat(amountInRupees);

    if (isNaN(amountFloat)) {
      // return "Invalid input";
      return null;
    }

    if (Number.isInteger(amountFloat)) {
      return amountFloat.toFixed(2);
    } else {
      return amountFloat.toFixed(2).replace(/\.?0*$/, ""); // Remove trailing zeros
    }
  };

  const doPayment = async () => {
    // setIsLoading(true);

    if (Object.keys(creditBuy).length > 0) {
      const amount = convertAmount(creditBuy.amount);
      const order_id = v4();
      creditController.current.abort();
      creditController.current = new AbortController();

      const data = {
        userId: userId,
        creditId: creditBuy.id,
        amount: {
          currency: "EUR",
          value: amount,
        },
        description: `Buy Credit For UserId: ${userId}, Amount: ${amount} And Order Id: ${order_id}`,
        redirectUrl: window.location.href,
        webhookUrl: `${
          API.getUri() || API.defaults.baseURL
        }offer/creditstatus/`,
        metadata: {
          order_id: order_id,
        },
      };

      API.post(`/offer/getcredit/`, data, {
        signal: creditController.current.signal,
      })
        .then((res) => {
          const resData = res.data;
          if (resData.status) {
            window.open(resData.paymentOrder, "_self");
          } else {
            toast.error("Something Went Wrong At Credit Purchase Process");
          }
        })
        .catch((error) => {
          if (!error.code || error.code !== "ERR_CANCELED") {
            toast.error("Something Went Wrong At Credit Purchase Process");
          }
        })
        .finally(() => {
          //   setIsLoading(false);
        });
    }
  };

  return (
    <CustomDialogBox
      sx={{ margin: "0", padding: "0" }}
      title={t("offer.professional.credits.buyCredits")}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Stack
          mr={1}
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              onClose();
            }}
          >
            {t("otherBooking.no")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              doPayment();
            }}
          >
            {t("offer.professional.credits.buyNow")}
          </Button>
        </Stack>
      }
    >
      <h4>
        {t("offer.professional.credits.areYouSure")} {creditBuy.credits}
        {t("offer.professional.credits.withAmount")} {creditBuy.amount}?{" "}
        {t("offer.professional.credits.thisActionCannotRevert")}
      </h4>
    </CustomDialogBox>
  );
};

export default DoPaymentDialogBox;
