import React from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <div className="pageStart" style={{ backgroundColor: "#F3F4F6" }}>
      <h1 style={{ textAlign: "center", marginBottom: "3rem" }}>
        {t("offers.terms_and_conditions_page.terms_conditions_dashbook_quote")}
      </h1>
      <div className="cardPage">
        <p>
          {t(
            "offers.terms_and_conditions_page.you_agree_following_terms_and_conditions"
          )}
        </p>
        <h3>{t("offers.terms_and_conditions_page.point_1")}</h3>
        <p>
          {t(
            "offers.terms_and_conditions_page.following_definitions_are_singular_and_plural"
          )}
        </p>
        <p>{t("offers.terms_and_conditions_page.point_1_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_4")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_5")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_6")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_7")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_8")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_9")}</p>
        <p>{t("offers.terms_and_conditions_page.point_1_10")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_2")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_2_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_2_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_2_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_2_4")}</p>
        <p>{t("offers.terms_and_conditions_page.point_2_5")}</p>
        <p>{t("offers.terms_and_conditions_page.point_2_6")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_3")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_3_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_3_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_3_3")}</p>
        <ul>
          <li>{t("offers.terms_and_conditions_page.interruptions_errors")}</li>
          <li>{t("offers.terms_and_conditions_page.free_of_viruses")}</li>
          <li>{t("offers.terms_and_conditions_page.service_is_correct")}</li>
          <li>
            {t("offers.terms_and_conditions_page.service_is_appropriate")}
          </li>
        </ul>
        <p>{t("offers.terms_and_conditions_page.point_3_4")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_4")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_4_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_4_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_4_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_4_4")}</p>
        <p>{t("offers.terms_and_conditions_page.point_4_5")}</p>
        <p>{t("offers.terms_and_conditions_page.point_4_6")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_5")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_5_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_4")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_5")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_6")}</p>
        <p>{t("offers.terms_and_conditions_page.point_5_7")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_6")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_6_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_6_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_6_3")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_7")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_7_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_7_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_7_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_7_4")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_8")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_8_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_8_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_8_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_8_4")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_9")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_9_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_9_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_9_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_9_4")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_10")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_10_1")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_2")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_3")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_4")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_5")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_6")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_7")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_8")}</p>
        <p>{t("offers.terms_and_conditions_page.point_10_9")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_11")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_11_1")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_12")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_12_1")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_13")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_13_1")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_14")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_14_1")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_15")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_15_1")}</p>

        <h3>{t("offers.terms_and_conditions_page.point_16")}</h3>
        <p>{t("offers.terms_and_conditions_page.point_16_1")}</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
