import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import TextareaAutoSize from "../../components/form/TextareaAutoSize";
import CheckBox from "../../components/form/CheckBox";
import { useSelector } from "react-redux";
import API from "../../config/axios";
import toast from "react-hot-toast";
import { highlightField } from "../../utils/Commons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CountriesMenu from "../../components/CountriesMenu";
import { useTranslation } from "react-i18next";
import LocationPicker from "../../components/form/LocationPicker";
import CustomDialogBox from "../../components/CustomDialogBox";
import { useNavigate } from "react-router-dom";

// Payment Form Validation Schema
const DeveloperOfferValidationSchema = yup.object().shape({
  workType: yup
    .array()
    .of(yup.object())
    .min(1, "Work Type Field must have at least one work"),
  explanation: yup.string().required(),
  termsAndCondition: yup
    .boolean()
    .required("Terms and conditions must be accepted")
    .oneOf([true], "Terms and conditions must be accepted"),

  deadline: yup.object().required("Deadline is required Field"),
  workMode: yup
    .object()
    .shape({
      value: yup
        .string()
        .required("Value is required Field")
        .notOneOf([null, ""], "Value cannot be null or empty"),
    })
    .required("Work Mode is required Field"),
  companyOwnerName: yup.string().required("Owner Name is required Field"),
  companyAddress: yup.string().required("Company Address is required Field"),
  companyEmail: yup
    .string()
    .email("Please Enter Valid Email Id")
    .required("Company Email is required Field"),
  companyPostalCode: yup
    .number()
    .required("Company PostalCode is required Field"),

  companyPhoneNo: yup
    .string()
    .length(10, "Phone number must be exactly 10 digits")
    .required(),
  companyCountryCode: yup.string().nullable(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
  companyName: yup.string().nullable(),

  companyWebsite: yup.string().nullable(),

  companyLocation: yup.boolean(),
  companyEmailPreference: yup.boolean(),
  companyQuoteRequest: yup.boolean().nullable(),
  companyCountryId: yup.string().nullable(),
});

export default function AddDeveloper({ handleClose }) {
  const { t } = useTranslation();
  const countries = useSelector((state) => state.common.countries);
  const navigate = useNavigate();

  const [inputErrors, setInputErrors] = useState({});
  const developerOfferController = useRef(new AbortController());

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [warningBox, setWarningBox] = useState(false);

  const saveAction = () => {
    setWarningBox(false);
    handleClose(); // close popup
  };
  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Dealines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  // Work Type Options
  const workType = [
    {
      name: t("offers.offer_create.website.website_and_shop_build"),
      value: "website",
    },
    { name: t("offers.offer_create.website.apps_build"), value: "apps" },
    { name: t("offers.offer_create.website.seo_and_sea"), value: "seoAndSea" },
  ];

  // Default Values For Create Developer Form
  const defaultValues = {
    companyCountryId: null,
    workType: [],
    workMode: null,
    deadline: null,
    explanation: "",
    companyAddressCoordinates: null,
    companyName: null,
    companyOwnerName: null,
    termsAndCondition: null,
    companyQuoteRequest: false,
    companyPhoneNo: null,
    companyCountryCode: null,
    companyEmail: null,
    companyWebsite: null,
    companyLocation: false,
    companyEmailPreference: false,
    companyAddress: null,
    companyPostalCode: null,
  };

  // Set Form Default Values
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(DeveloperOfferValidationSchema),
  });

  // Error Handler (Validation)
  const onError = (errors) => {
    console.log(errors);
    let newErrorObj = {};
    Object.keys(errors).forEach((item) => {
      if (item === "companyAddressCoordinates") {
        const key = "companyAddress";
        newErrorObj[key] = errors[key]["message"];
      } else {
        const key = item;
        newErrorObj[key] = errors[key]["message"];
      }
    });
    setInputErrors(newErrorObj);
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error);
      console.warn("Field cannot be highlighted");
    }
  };

  // Create New Developer Via Api
  const createDeveloperOffer = (formData) => {
    developerOfferController.current.abort();
    developerOfferController.current = new AbortController();

    API.post(
      `/offer/`,
      { offerType: "developer", ...formData },
      {
        signal: developerOfferController.current.signal,
      }
    )
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setWarningBox(true);
        } else {
          toast.error("Something Went Wrong At Create Developer Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Create Developer Offer");
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };

  // On Click Event For Phone And Country Select
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Set Country Code And Country Id
  useEffect(() => {
    if (countryCode && countryCode !== "") {
      setValue("companyCountryCode", countryCode);
      const country = countries.find((country) => country.code === countryCode);
      if (country && country.id) setValue("companyCountryId", country.id);
    }
    // eslint-disable-next-line
  }, [countryCode]);

  useEffect(() => {
    setInputErrors({});
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-1">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(createDeveloperOffer, onError)}
      >
        <CustomDialogBox
          isOpen={warningBox}
          maxWidth={"xs"}
          title={t("offers.offer_create.success_box.title")}
          onClose={() => {
            setWarningBox(false);
            handleClose(); // close popup
          }}
          children={
            <div>
              <p>{t("offers.offer_create.success_box.tnx_for_req")}</p>
              <p>{t("offers.offer_create.success_box.check_mail")}</p>
              <p>{t("offers.offer_create.success_box.we_sent_mail")}</p>
              <p>{t("offers.offer_create.success_box.ask_rcv_email")}</p>
              <p>{t("offers.offer_create.success_box.check_spam")}</p>
              <p>{t("offers.offer_create.success_box.add_us_allow_list")}</p>
              <p>
                {t("offers.offer_create.success_box.contact_us_via")}
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                    color: "#D0AF88",
                  }}
                  onClick={() => {
                    setWarningBox(false);
                    handleClose(); // close popup
                    navigate("/questions?number=12");
                  }}
                >
                  {t("offers.offer_create.success_box.contact_us_via_link")}
                </button>
              </p>
            </div>
          }
          actions={
            <Button
              type="submit"
              variant="contained"
              className="cancelButton"
              onClick={saveAction}
            >
              {t("offers.offer_create.success_box.close")}
            </Button>
          }
        />
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                multiple={true}
                name="workType"
                labelTheme={true}
                id="workType"
                label={
                  t("offers.offer_create.website.what_work") +
                  ` (${t("offers.offer_create.website.select_multiple")})`
                }
                type="text"
                control={control}
                options={workType}
                getOptionLabel={(option) => option.name}
                loading={false}
                customError={inputErrors.workType || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={8}>
              <TextareaAutoSize
                label={t(
                  "offers.offer_create.website.what_are_you_looking_web_builder"
                )}
                type="text"
                labelTheme={true}
                name="explanation"
                control={control}
                customError={inputErrors.description || null}
                placeholder={
                  t(
                    "offers.offer_create.website.website_web_shop_discription"
                  ) +
                  "\n" +
                  t("offers.offer_create.website.what_are_wishes")
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="workMode"
                type="text"
                labelTheme={true}
                label={t("offers.offer_create.website.form_of_contact")}
                control={control}
                options={workMode}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                customError={inputErrors?.workMode || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="deadline"
                type="text"
                labelTheme={true}
                label={t("offers.offer_create.website.deadline")}
                control={control}
                options={deadLine}
                getOptionLabel={(option) => option.name}
                disableClearable={true}
                customError={
                  inputErrors.deadline?.value || inputErrors.deadline || null
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ color: "#d18615" }} fontSize="20px">
              {t(`offers.offer_create.website.contact_details`)}
            </Typography>
            <Typography color="black" fontSize="20px">
              {t(`offers.offer_create.website.contact_details_will_private`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyOwnerName"
                type="text"
                labelTheme={true}
                label={t("offers.offer_create.website.your_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyOwnerName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyName"
                type="text"
                labelTheme={true}
                label={t("offers.offer_create.website.comapny_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyPostalCode"
                  type="text"
                  labelTheme={true}
                  label={t("offers.offer_create.website.post_code")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyPostalCode || null}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t(
                    "offers.offer_create.website.located_outside_netherland"
                  )}
                  control={control}
                  name="companyLocation"
                  labelTheme={true}
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyLocation || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyEmail"
                labelTheme={true}
                type="email"
                label={t("offers.offer_create.website.email")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyEmail || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyWebsite"
                labelTheme={true}
                type="text"
                label={t("offers.offer_create.website.website")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <div>
                  <TextInput
                    name="companyPhoneNo"
                    labelTheme={true}
                    label={t("offers.offer_create.website.mobile_number")}
                    control={control}
                    customError={inputErrors.companyPhoneNo || null}
                    fullWidth
                    onChangeCallback={(inputValue) => {
                      const newValue = inputValue
                        .replace(/^0+/, "")
                        .replace(/[^0-9]/g, "");
                      setValue("companyPhoneNo", newValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                        >
                          +{countryCode}
                          <KeyboardArrowDownIcon
                            sx={{ color: "grey", fontSize: 16 }}
                          />
                        </Box>
                      ),
                    }}
                  />
                  <CountriesMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    open={open}
                    setOpen={setOpen}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t("offers.offer_create.website.email_to_receive")}
                  control={control}
                  name="companyEmailPreference"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyEmailPreference || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <LocationPicker
                control={control}
                labelTheme={true}
                placeholder={t("offers.offer_create.website.company_address")}
                customError={inputErrors.companyAddress || null}
                setValue={setValue}
                inputName={"companyAddress"}
                CoordinatesTagName={"companyAddressCoordinates"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
            <Grid item sm={6} xs={12} md={4}>
              <CheckBox
                label={t("offers.offer_create.website.company_quote_request")}
                control={control}
                name="companyQuoteRequest"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.companyQuoteRequest || null}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ paddingLeft: "0px !important", paddingTop: "0px !important" }}
          >
            <Box sx={{ width: "max-content" }}>
              <CheckBox
                label={t("offers.offer_create.website.accept_condition")}
                control={control}
                name="termsAndCondition"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.termsAndCondition || null}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "max-content", marginX: "auto" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{ bgcolor: "green", color: "white" }}
              >
                {t("offers.offer_create.request_quotes")}
                <img
                  alt=""
                  src={"./nextWhite.png"}
                  style={{ marginLeft: "10px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <div>
        <div className="flex" style={{ marginBottom: "0.5rem" }}>
          <img src="./greenCheck.png" alt="" style={{ marginRight: "10px" }} />
          <Typography
            variant="body1"
            align="center"
            style={{ color: "#0fa958" }}
          >
            {t("offers.offer_create.request_free_quotes")}
          </Typography>
        </div>
        <div className="flex">
          <img src="./greenCheck.png" alt="" style={{ marginRight: "10px" }} />
          <Typography
            variant="body1"
            align="center"
            style={{ color: "#0fa958" }}
          >
            Binnen 24 uur ontvang je meerdere offertes
          </Typography>
        </div>
      </div>
    </div>
  );
}
