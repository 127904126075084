import { Grid, Typography } from "@mui/material";
import React from "react";
import AutocompleteInput from "../../../components/form/AutocompleteInput";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import TextInput from "../../../components/form/TextInput";

function EmotionalCoach({ t, control, inputErrors }) {
  const title = t("offers.offer_create.coach.request_emotional_coach");

  const gender = [
    { name: t("offers.offer_create.coach.gender_male"), value: "male" },
    { name: t("offers.offer_create.coach.gender_female"), value: "female" },
  ];
  const helpType = [
    {
      name: t("offers.offer_create.coach.help_loosing_loved_one"),
      value: "loosingLovedOne",
    },
    { name: t("offers.offer_create.coach.help_trauma"), value: "trauma" },
    { name: t("offers.offer_create.coach.help_other"), value: "other" },
  ];
  const contactType = [
    {
      name: t("offers.offer_create.coach.contact_personal_contact"),
      value: "personal",
    },
    {
      name: t("offers.offer_create.coach.contact_remote_contact"),
      value: "remote",
    },
    {
      name: t(
        "offers.offer_create.coach.contact_partly_person_partly_remotely"
      ),
      value: "partlyPersonAndRemotely",
    },
  ];
  const subCoachType = [
    {
      name: t("offers.offer_create.coach.coachsub_no_preference"),
      value: "noPreference",
    },
    {
      name: t("offers.offer_create.coach.coachsub_both_feet_and_ground"),
      value: "bothFeetAndGround",
    },
    {
      name: t("offers.offer_create.coach.coachsub_soft_matters"),
      value: "softMatters",
    },
  ];
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  return (
    <div>
      <Typography variant="body1" style={{ paddingLeft: "1rem" }}>
        {title}
      </Typography>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="helpType"
              labelTheme={true}
              id="helpType"
              label={t("offers.offer_create.coach.label_help_type")}
              type="text"
              control={control}
              options={helpType}
              getOptionLabel={(option) => option.name}
              loading={false}
              customError={inputErrors.helpType || null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="subCoachType"
              labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_sub_coach_type")}
              control={control}
              options={subCoachType}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.subCoachType?.value ||
                inputErrors.subCoachType ||
                null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={8}>
            <TextareaAutoSize
              label={t("offers.offer_create.accountant.lable.explanation")}
              type="text"
              labelTheme={true}
              name="explanation"
              control={control}
              customError={inputErrors.explanation || null}
              placeholder={t(
                "offers.offer_create.coach.explanation_emotional_coach"
              ).replace(/\/n/g, "\n")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="gender"
              labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_gender")}
              control={control}
              options={gender}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.gender?.value || inputErrors.gender || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <TextInput
              name="age"
              labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_age")}
              autoFocus={true}
              control={control}
              customError={inputErrors.age || null}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="deadline"
              labelTheme={true}
              type="text"
              label={t("offers.offer_create.website.deadline")}
              control={control}
              options={deadLine}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.deadline?.value || inputErrors.deadline || null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} xs={12} md={4}>
            <AutocompleteInput
              name="contactType"
              labelTheme={true}
              type="text"
              label={t("offers.offer_create.coach.label_contact_type")}
              control={control}
              options={contactType}
              getOptionLabel={(option) => option.name}
              disableClearable={true}
              customError={
                inputErrors.contactType?.value ||
                inputErrors.contactType ||
                null
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EmotionalCoach;
