import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomDialogBox from "../../../components/CustomDialogBox";

const ShowOfferDetailsDialogBox = ({ isOpen, onClose, offer }) => {
  const { t } = useTranslation();

  // Work Mode Options
  const workMode = [
    {
      name: t("offer.professional.professionalList.remotely"),
      value: "remotely",
    },
    {
      name: t("offer.professional.professionalList.hybrid"),
      value: "hybrid",
    },
  ];

  // Business Mode Options
  const businessForm = [
    {
      name: t("offer.professional.professionalList.self_employed"),
      value: "selfEmployed",
    },
    {
      name: t("offer.professional.professionalList.proprietorship"),
      value: "proprietorship",
    },
    {
      name: t("offer.professional.professionalList.partnership_vof"),
      value: "partnershipVOF",
    },
    {
      name: t("offer.professional.professionalList.partnership_maatschap"),
      value: "partnershipMaatschap",
    },
    {
      name: t("offer.professional.professionalList.bv"),
      value: "partnershipBV",
    },
    {
      name: t("offer.professional.professionalList.association_or_foundation"),
      value: "association",
    },
    {
      name: t("offer.professional.professionalList.nonprofit_or_government"),
      value: "nonProfit",
    },
    {
      name: t("offer.professional.professionalList.none_private"),
      value: "nonePrivate",
    },
    { name: t("offer.professional.professionalList.other"), value: "other" },
  ];

  // Work Type Options
  const workType = [
    {
      name: t("offer.professional.professionalList.complete_administration"),
      value: "completeAdministration",
    },
    {
      name: t(
        "offer.professional.professionalList.annual_accounts_and_tax_returns",
      ),
      value: "annualAccTaxReturns",
    },
    {
      name: t("offer.professional.professionalList.tax_returns"),
      value: "taxReturns",
    },
    {
      name: t("offer.professional.professionalList.tax_advice"),
      value: "taxAdvice",
    },
    {
      name: t("offer.professional.professionalList.auditor_report"),
      value: "auditorsReport",
    },
    {
      name: t("offer.professional.professionalList.objection_letter"),
      value: "drawObjectionLetter",
    },
    {
      name: t("offer.professional.professionalList.business_plan"),
      value: "drawBusinessPlan",
    },
    {
      name: t("offer.professional.professionalList.other_activities"),
      value: "otherActivities",
    },
    {
      name: t("offer.professional.professionalList.collecting_data"),
      value: "collectingData",
    },
    {
      name: t("offer.professional.professionalList.process_data"),
      value: "processData",
    },
    {
      name: t("offer.professional.professionalList.analyze_data"),
      value: "analyzeData",
    },
    {
      name: t("offer.professional.professionalList.report_insights"),
      value: "reportInsights",
    },
    {
      name: t("offer.professional.professionalList.checking_contract"),
      value: "checkingContract",
    },
    {
      name: t(
        "offer.professional.professionalList.checking_general_terms_and_conditions",
      ),
      value: "checkingTermsConditions",
    },
    {
      name: t("offer.professional.professionalList.tax_matters"),
      value: "taxMatters",
    },
    {
      name: t("offer.professional.professionalList.business_conflict"),
      value: "businessConflict",
    },
    {
      name: t("offer.professional.professionalList.family_matters"),
      value: "familyMatters",
    },
    {
      name: t("offer.professional.professionalList.real_estate"),
      value: "realEstate",
    },
    {
      name: t("offer.professional.professionalList.personal_injury"),
      value: "personalInjury",
    },
    {
      name: t("offer.professional.professionalList.otherForm"),
      value: "other",
    },
  ];

  // Deadlines Options
  const deadLine = [
    {
      name: t("offer.professional.professionalList.deadline_asap"),
      value: "asap",
    },
    {
      name: t("offer.professional.professionalList.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offer.professional.professionalList.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offer.professional.professionalList.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offer.professional.professionalList.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  // Find label based on value
  const getWorkModeLabel = (value) => {
    const mode = workMode.find((mode) => mode.value === value);
    return mode ? mode.name : "";
  };

  const getBusinessFormLabel = (value) => {
    const business = businessForm.find((form) => form.value === value);
    return business ? business.name : "";
  };

  const getWorkTypeLabel = (value) => {
    return value
      .map((val) => {
        const mode = workType.find((mode) => mode.value === val);
        return mode ? mode.name : "";
      })
      .join(", ");
  };

  const getDeadlineLabel = (value) => {
    const line = deadLine.find((line) => line.value === value);
    return line ? line.name : "";
  };

  return (
    <>
      <CustomDialogBox
        title={t("offer.professional.professionalList.offerDetails")}
        isOpen={isOpen}
        onClose={onClose}
      >
        {offer.businessForm && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6">
                {t("offer.professional.professionalList.fields.businessForm")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">
                {getBusinessFormLabel(offer.businessForm)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {offer?.workType && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6">
                {t("offer.professional.professionalList.fields.workType")}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">
                {getWorkTypeLabel(offer?.workType)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6">
              {t("offer.professional.professionalList.fields.explanation")}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subtitle1">{offer?.explanation}</Typography>
          </Grid>
        </Grid>
        {offer?.workMode && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6">
                {t(
                  "offer.professional.professionalList.fields.desiredLocation",
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">
                {" "}
                {getWorkModeLabel(offer?.workMode)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {offer?.deadline && (
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="h6">
                {t("offer.professional.professionalList.fields.deadline")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography variant="subtitle1">
                {getDeadlineLabel(offer?.deadline)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CustomDialogBox>
    </>
  );
};

export default ShowOfferDetailsDialogBox;
