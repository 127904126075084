import React from "react";
import { useTranslation } from "react-i18next";

function DeveloperDetails({ offer }) {
  const { t } = useTranslation();

  const workType = [
    {
      name: t("offers.offer_create.website.website_and_shop_build"),
      value: "website",
    },
    { name: t("offers.offer_create.website.apps_build"), value: "apps" },
    { name: t("offers.offer_create.website.seo_and_sea"), value: "seoAndSea" },
  ];
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.website.what_work")}</p>
      <ul className="answers">
        {offer.apps === true ? (
          <li>{workType.find((item) => item.value === "apps").name}</li>
        ) : (
          ""
        )}
        {offer.website === true ? (
          <li>{workType.find((item) => item.value === "website").name}</li>
        ) : (
          ""
        )}
        {offer.seoAndSea === true ? (
          <li>{workType.find((item) => item.value === "seoAndSea").name}</li>
        ) : (
          ""
        )}
      </ul>

      <p className="detailsLabel">{t("offers.offer_create.website.what_are_you_looking_web_builder")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.form_of_contact")}</p>
      <p className="detailsValue">{workMode.find((item) => item.value === offer.workMode).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
    </div>
  );
}

export default DeveloperDetails;
