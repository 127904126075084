import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/auth";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./components/Loading";

import "./App.css";

import routes from "./routes";
import { LoadScript } from "@react-google-maps/api";


const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#0d0d0d",
          },
          primaryText: {
            light: "#161616",
            dark: "#f2f2f2",
          },
          primaryBg: {
            light: "#ffffff",
            dark: "#f4f9fe",
          },
          secondary: {
            main: "#c28947",
          },
          info: {
            main: "#0080ff",
          },
          success: {
            main: "#00b571",
          },
          purple: {
            main: "#8e44ad",
          },
        }
      : {
          primary: {
            main: "#0d0d0d",
          },
          primaryText: {
            light: "#fff",
            dark: "rgba(255, 255, 255, 0.7)",
          },
          primaryBg: {
            light: "#252525",
            dark: "#111111",
          },
          secondary: {
            main: "#c28947",
          },
          info: {
            main: "#0080ff",
          },
          success: {
            main: "#00b571",
          },
          purple: {
            main: "#8e44ad",
          },
        }),
  },
});

export default function App() {
  // eslint-disable-next-line
  const [mode, setMode] = React.useState("light");

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);



  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyDFiVq_Gorb0NV4fP_xhQgJN7C0XLR8nAY"}
      libraries={["places"]}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
              className: "",
              duration: 2000,
              style: {
                background: mode === "light" ? "#363636" : "#fff",
                color: mode === "light" ? "#fff" : "#363636",
              },
            }}
          />
          <Loading />
          <BrowserRouter>
            <ScrollToTop />
            {routes}
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </LoadScript>
  );
}
