import { useAuth } from "../hooks/auth";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  console.log(location.pathname, "location.pathname");
  const publicRoutes = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/register",
  ];
  const privetRoutes = "/professionalList";

  if (!auth?.user && !localStorage.getItem("dashbook_user")) {
    return (
      <Navigate
        to={location.pathname.includes(privetRoutes) ? "/" : "/login"}
        state={{ path: location.pathname }}
      />
    );
  }

  if (publicRoutes.includes(location.pathname)) {
    return <Navigate to="/" />;
  }

  return children;
};
