import React from "react";
import { useTranslation } from "react-i18next";

function GraphicDesignerDetails({ offer }) {
  const { t } = useTranslation();
  // Design Type Options
  const designKind = [
    {
      name: t("offers.offer_create.graphic_designer.logo_design"),
      value: "logoDesign",
    },
    {
      name: t("offers.offer_create.graphic_designer.corporate_identity_design"),
      value: "corporateIdentityDesign",
    },
    {
      name: t("offers.offer_create.graphic_designer.website_design"),
      value: "websiteDesign",
    },
    {
      name: t(
        "offers.offer_create.graphic_designer.animations_or_illustration"
      ),
      value: "animationsOrIllustration",
    },
    {
      name: t("offers.offer_create.graphic_designer.preparing_printed_matter"),
      value: "printedMatter",
    },
    { name: t("offers.offer_create.graphic_designer.other"), value: "other" },
  ];

  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Deadlines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];
  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.graphic_designer.kind_of_design")}</p>
      <ul className="answers">
        {offer.designKind.map((item)=>(
            <li>{designKind.find((work)=>work.value === item).name}</li>
        ))}
      </ul>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.desired_location")}</p>
      <p className="detailsValue">{workMode.find((item) => item.value === offer.workMode).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
    </div>
  );
}

export default GraphicDesignerDetails;
