import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Button, Typography } from "@mui/material";
import API from "../../config/axios";
// import { useAuth } from "../hooks/auth";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DialogBox from "../../components/DialogBox";
import DeveloperDetails from "../offerDetails/DeveloperDetails";
import AccountantDetails from "../offerDetails/AccountantDetails";
import GraphicDesignerDetails from "../offerDetails/GraphicDesignerDetails";
import AdvocateDetails from "../offerDetails/AdvocateDetails";
import DataAnalystDetails from "../offerDetails/DataAnalystDetails";
import CoachDetails from "../offerDetails/CoachDetails";

function ApprovedOfferList({ filterOffer }) {
  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [allApprovedOffer, setAllApprovedOffer] = useState([]);

  const offerController = useRef(new AbortController());
  const { t } = useTranslation();
  const [showOffer, setShowOffer] = useState({});

  const getAllOffer = async () => {
    offerController.current.abort();
    offerController.current = new AbortController();

    const query = {
      status: "approved",
      applicationDate: "last_three_days",
      offerType: filterOffer,
    };

    API.get("/offer/list", {
      signal: offerController.current.signal,
      params: query,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setAllApprovedOffer(resData.data);
        } else {
          toast.error("Offer List cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  // Edit
  // const applyOffer = (offer) => {
  //   const offerId = offer._id;
  //   const credit = offer.credit;

  //   if (userId && offerId && credit) {
  //     // Navigate To Path
  //     offerController.current.abort();
  //     offerController.current = new AbortController();

  //     API.put(
  //       `/offer/apply/${offerId}`,
  //       { userId },
  //       {
  //         signal: offerController.current.signal,
  //       }
  //     )
  //       .then((res) => {
  //         const resData = res.data;

  //         if (resData.status) {
  //           toast.success("Applied to the Offer");
  //         } else {
  //           toast.error("Offer cannot be Applied");
  //         }
  //       })
  //       .catch((error) => {
  //         if (!error.code || error.code !== "ERR_CANCELED") {
  //           toast.error("Something went wrong");
  //         }
  //       });
  //   } else {
  //     navigate("/login");
  //   }
  // };

  const showBuyConfirmationDialog = () => {
    setShowBuyDialog(true);
  };

  const closeBuyConfirmationDialog = () => {
    setShowBuyDialog(false);
  };

  const offerListTableRows = allApprovedOffer?.map((offer, offerIndex) => {
    return (
      <div
        className="cardBox"
        style={{ textAlign: "left" }}
        key={"offer" + offerIndex}
      >
        <div>
          <img alt="" src={"./Quote.png"} />
        </div>
        <div className="cardInfo">
          <Typography
            variant="body1"
            style={{ margin: "0px", padding: "0px", overflow: "hidden" }}
          >
            {offer.explanation ? offer.explanation : "Explanation Not Found"}
          </Typography>
        </div>
        <Button
          variant="outlined"
          color="primary"
          className="cancelButton"
          onClick={() => {
            setShowOffer(offer);
            showBuyConfirmationDialog();
          }}
        >
          {t("offers.home_page.offer_list.view_details")}
        </Button>
      </div>
    );
  });

  const goToApply = () => {
    window.location.href = "https://dashbook.nl/login";
  };

  const goBack = () => {
    const carouselElement = document.getElementById("carousel");
    if (carouselElement) {
      const scrollBarWidth = carouselElement.offsetWidth;
      const currentPosition = carouselElement.scrollLeft;

      if (currentPosition > 0) {
        if (currentPosition - scrollBarWidth > 0) {
          carouselElement.scrollTo({
            left: currentPosition - scrollBarWidth,
            behavior: "smooth",
          });
        } else {
          carouselElement.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        }
      }
    }
  };
  const goNext = () => {
    const carouselElement = document.getElementById("carousel");

    if (carouselElement) {
      const maxScrollValue =
        carouselElement.scrollWidth - carouselElement.clientWidth;
      const scrollBarWidth = carouselElement.offsetWidth;
      const currentPosition = carouselElement.scrollLeft;

      if (currentPosition < maxScrollValue) {
        if (currentPosition + scrollBarWidth <= maxScrollValue) {
          carouselElement.scrollTo({
            left: currentPosition + scrollBarWidth,
            behavior: "smooth",
          });
        } else {
          carouselElement.scrollTo({
            left: maxScrollValue,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    getAllOffer();
    // eslint-disable-next-line
  }, [filterOffer]);

  useEffect(() => {
    getAllOffer();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginBottom: "1rem" }}>
      {offerListTableRows.length > 0 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {offerListTableRows.length > 4 ? (
            <button
              className="carouselBtn"
              style={{ marginRight: "10px" }}
              onClick={goBack}
            >
              <img alt="" src={"./BackIcon.png"} />
            </button>
          ) : (
            ""
          )}

          <div
            className="carousel"
            id="carousel"
            style={{ maxWidth: "1050px", width: "-webkit-fill-available" }}
          >
            {offerListTableRows.length > 0 ? (
              offerListTableRows
            ) : (
              <div
                style={{
                  margin: "1rem 0px",
                  backgroundColor: "white",
                  padding: "1rem",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    overflow: "hidden",
                  }}
                >
                  {t("offers.home_page.offer_list.no_offer_found")}
                </Typography>
              </div>
            )}
          </div>
          {offerListTableRows.length > 4 ? (
            <button
              className="carouselBtn"
              style={{ marginLeft: "10px" }}
              onClick={goNext}
            >
              <img alt="" src={"./NextIcon.png"} />
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          style={{
            margin: "1rem 0px",
            backgroundColor: "white",
            padding: "1rem",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              margin: "0px",
              padding: "0px",
              overflow: "hidden",
            }}
          >
            {t("offers.home_page.offer_list.no_offer_found")}
          </Typography>
        </div>
      )}

      <DialogBox
        open={showBuyDialog}
        handleOpen={showBuyConfirmationDialog}
        handleClose={closeBuyConfirmationDialog}
        title={t("offers.home_page.offer_list.offer_details_title")}
        component={
          <div>
            <div
              style={{
                maxHeight: "320px",
                overflowY: "scroll",
                padding: "0px 1rem",
              }}
            >
              {showOffer && showOffer.offerType === "developer" ? (
                <DeveloperDetails offer={showOffer} />
              ) : (
                ""
              )}
              {showOffer && showOffer.offerType === "accountant" ? (
                <AccountantDetails offer={showOffer} />
              ) : (
                ""
              )}
              {showOffer && showOffer.offerType === "graphicDesigner" ? (
                <GraphicDesignerDetails offer={showOffer} />
              ) : (
                ""
              )}
              {showOffer &&
              (showOffer.offerType === "careerCoach" ||
                showOffer.offerType === "businessCoach" ||
                showOffer.offerType === "lifeCoach" ||
                showOffer.offerType === "emotionalCoach" ||
                showOffer.offerType === "budgetCoach") ? (
                <CoachDetails offer={showOffer} />
              ) : (
                ""
              )}
              {showOffer && showOffer.offerType === "advocate" ? (
                <AdvocateDetails offer={showOffer} />
              ) : (
                ""
              )}
              {showOffer && showOffer.offerType === "dataAnalist" ? (
                <DataAnalystDetails offer={showOffer} />
              ) : (
                ""
              )}
            </div>
            <div className="boxActions" style={{ justifyContent: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                className="mainButton"
                sx={{ marginRight: "1rem" }}
                onClick={goToApply}
              >
                {t("offers.header.login")}
              </Button>
              <Button
                variant="outlined"
                className="cancelButton"
                onClick={closeBuyConfirmationDialog}
              >
                {t("offers.buy_credits_page.cancel")}
              </Button>
            </div>
          </div>
        }
        maxWidth={"sm"}
        height={"max-content !important"}
      />
    </div>
  );
}

export default ApprovedOfferList;
