import React from "react";
import { useTranslation } from "react-i18next";

function BusinessCoachDetails({ offer }) {
  const { t } = useTranslation();

  const contactType = [
    {
      name: t("offers.offer_create.coach.contact_personal_contact"),
      value: "personal",
    },
    {
      name: t("offers.offer_create.coach.contact_remote_contact"),
      value: "remote",
    },
    {
      name: t(
        "offers.offer_create.coach.contact_partly_person_partly_remotely"
      ),
      value: "partlyPersonAndRemotely",
    },
  ];

  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  const helpType = [
    {
      name: t("offers.offer_create.coach.help_start_up_company"),
      value: "startUpCompany",
    },
    {
      name: t("offers.offer_create.coach.help_business_growth"),
      value: "businessGrowth",
    },
    {
      name: t("offers.offer_create.coach.help_professionalizing_my_company"),
      value: "professionalizingCompany",
    },
    {
      name: t("offers.offer_create.coach.help_international_expansion"),
      value: "internationalExpansion",
    },
    {
      name: t("offers.offer_create.coach.help_management_skills"),
      value: "managementSkills",
    },
    { name: t("offers.offer_create.coach.help_other"), value: "other" },
  ];


  return (
    <div>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_help_type")}</p>
      <ul className="answers">
        {offer.helpType.map((help) => (
          <li>{helpType.find((item) => item.value === help).name}</li>
        ))}
      </ul>
      <p className="detailsLabel">{t("offers.offer_create.accountant.lable.explanation")}</p>
      <p className="detailsValue">{offer.explanation}</p>
      <p className="detailsLabel">{t("offers.offer_create.website.deadline")}</p>
      <p className="detailsValue">{deadLine.find((item) => item.value === offer.deadline).name}</p>
      <p className="detailsLabel">{t("offers.offer_create.coach.label_contact_type")}</p>
      <p className="detailsValue">{contactType.find((item) => item.value === offer.contactType).name}</p>
    </div>
  );
}

export default BusinessCoachDetails;
