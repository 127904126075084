import { Routes, Route } from "react-router-dom";
import React from "react";
import { RequireAuth } from "../utils/RequireAuth";
import { PublicRoute } from "../utils/PublicRoute";
import { NotFound } from "../pages/NotFound";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import HomePage from "../pages/HomePage";
import Contact from "../pages/footerPages/Contact";
import About from "../pages/footerPages/About";
import HowItWorks from "../pages/footerPages/HowItWorks";
import Faq from "../pages/footerPages/Faq";
import TermsAndConditions from "../pages/footerPages/TermsAndConditions";
import PrivacyPolicy from "../pages/footerPages/PrivacyPolicy";
import CookiePolicy from "../pages/footerPages/CookiePolicy";
import Sitemap from "../pages/footerPages/Sitemap";
// import BusinessDirectory from "../pages/footerPages/BusinessDirectory";
import BuyCredits from "../pages/footerPages/BuyCredits";
import CommanPage from "../pages/components/CommanPage";
import ProfessionalList from "../pages/professional/professionalList";
import AppsAndDeals from "../pages/professional/professionalTabs";

const routes = (
  <Routes>
    <Route
      index
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/register"
      element={
        <PublicRoute>
          <Register />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/reset-password"
      element={
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      }
    />
    <Route
      path="/"
      element={
        <PublicRoute>
          <CommanPage child={<HomePage />} />
        </PublicRoute>
      }
    >
      {/* <Route path="/" element={<Navigate to="dashboard" replace />} /> */}
    </Route>

    <Route path="*" element={<NotFound />} />
    <Route path="/contact" element={<CommanPage child={<Contact />} />} />
    <Route path="/about" element={<CommanPage child={<About />} />} />
    <Route path="/work" element={<CommanPage child={<HowItWorks />} />} />
    <Route path="/questions" element={<CommanPage child={<Faq />} />} />
    <Route
      path="/conditions"
      element={<CommanPage child={<TermsAndConditions />} />}
    />
    <Route path="/privacy" element={<CommanPage child={<PrivacyPolicy />} />} />
    <Route path="/cookie" element={<CommanPage child={<CookiePolicy />} />} />
    <Route path="/sitemap" element={<CommanPage child={<Sitemap />} />} />
    {/* <Route
      path="/directory"
      element={<CommanPage child={<BusinessDirectory />} />}
    /> */}
    <Route path="/buycredits" element={<CommanPage child={<BuyCredits />} />} />
    <Route
      path="/professionalList/*"
      element={
        <CommanPage
          child={
            <RequireAuth>
              <AppsAndDeals />
            </RequireAuth>
          }
        />
      }
    />
  </Routes>
);

export default routes;
